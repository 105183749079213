import { Component, JSX, mergeProps, Show, splitProps } from 'solid-js';
import { Dynamic } from 'solid-js/web';

export const FieldLabel: Component<
    {
        tag?: string;
        label?: JSX.Element;
        labelWidth?: 'string';
        labelPosition?: 'left' | 'right' | 'above';
        labelClass?: string;
        contentClass?: string;
        disabled?: boolean;
        invalid?: boolean | JSX.Element;
        hint?: JSX.Element;
    } & JSX.HTMLAttributes<HTMLElement>
> = (_props) => {
    const propsWithDefaults = mergeProps(
        { tag: 'label', labelPosition: 'above', labelClass: '', contentClass: '', class: '' },
        _props,
    );
    const [props, propsRest] = splitProps(propsWithDefaults, [
        'tag',
        'label',
        'labelWidth',
        'labelPosition',
        'labelClass',
        'contentClass',
        'disabled',
        'invalid',
        'hint',
        'class',
        'children',
    ]);

    const invalid = () => !!props.invalid;
    const hint = () => (props.invalid && typeof props.invalid !== 'boolean' ? props.invalid : props.hint);

    return (
        <Dynamic
            component={props.tag}
            class={`o-field-label o-field-label--${props.labelPosition ?? 'above'} ${
                props.disabled ? 'is-disabled' : ''
            } ${invalid() ? 'is-invalid' : ''} ${props.class}`}
            {...propsRest}
        >
            <Show when={props.label}>
                <div
                    class={`o-field-label__label ${props.labelClass}`}
                    style={props.labelWidth ? { width: props.labelWidth } : undefined}
                >
                    {props.label}
                </div>
            </Show>
            <div class={`o-field-label__content ${props.contentClass}`}>
                {props.children}
                <Show when={hint()} keyed>
                    {(hint) => <div class="o-field-label__hint">{hint}</div>}
                </Show>
            </div>
        </Dynamic>
    );
};
