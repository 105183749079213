import { useIntl } from '@cookbook/solid-intl';
import { Component, createEffect, createSignal, JSX, Show } from 'solid-js';

import { NoteVisibility } from '@/entities/note';
import { useOnDemand } from '@/iro/hooks/onDemand';
import { Form, FormItem } from '@/iro/layouts/Form';
import { ActionButton } from '@/iro/objects/ActionButton';
import { Bubble } from '@/iro/objects/Bubble';
import { Button } from '@/iro/objects/Button';
import { Dialog } from '@/iro/objects/Dialog';
import { Divider } from '@/iro/objects/Divider';
import { Icon } from '@/iro/objects/Icon';
import { OverflowButton } from '@/iro/objects/OverflowButton';
import { TextArea } from '@/iro/objects/TextArea';
import { TextField } from '@/iro/objects/TextField';
import { Note } from '@/store/note';
import icons from '#/icons.svg';

import { TimelineNote } from '../components/Note';
import { NoteVisibilityMenu } from '../menus/NoteVisibilityMenu';

export const ComposeModal: Component<{
    onClose?: (trigger?: string) => void;
    replyTo?: Note;
}> = (props) => {
    const intl = useIntl();

    const [text, setText] = createSignal('');
    const [subject, setSubject] = createSignal('');
    const [visibility, setVisibility] = createSignal(NoteVisibility.Public);

    const visibilityData = () =>
        ({
            [NoteVisibility.Public]: { icon: 'globe', text: 'Public' },
            [NoteVisibility.Home]: { icon: 'home', text: 'Unlisted' },
            [NoteVisibility.Followers]: { icon: 'users', text: 'Followers' },
            [NoteVisibility.Specified]: { icon: 'envelope', text: 'Direct' },
        }[visibility()]);

    const [createVisibilityMenu, visibilityMenuVisible] = useOnDemand();

    const handleVisibilityMenu: JSX.EventHandler<HTMLButtonElement, MouseEvent> = (e) => {
        e.preventDefault();

        const anchor = e.currentTarget;

        createVisibilityMenu((close) => {
            const handleClose = (visibility?: NoteVisibility) => {
                if (visibility) {
                    setVisibility(visibility);
                }
                close();
            };

            return <NoteVisibilityMenu anchor={anchor} align="up-left" onClose={handleClose} />;
        });
    };

    const handleSetText: JSX.EventHandler<HTMLTextAreaElement, InputEvent> = (e) => setText(e.currentTarget.value);
    const handleSetSubject: JSX.EventHandler<HTMLInputElement, InputEvent> = (e) => setSubject(e.currentTarget.value);

    const handleClose = async (result?: string) => {
        if (result === 'ok') {
            //
        }
        props.onClose?.(result);
    };

    createEffect(() => props.replyTo?.cw && setSubject(props.replyTo.cw));

    return (
        <Dialog
            title={
                props.replyTo
                    ? intl.formatMessage({ id: 'modal.compose.title.reply', defaultMessage: 'New reply' })
                    : intl.formatMessage({ id: 'modal.compose.title.new', defaultMessage: 'New note' })
            }
            footer={
                <>
                    <div class="l-media__block l-media__block--main">
                        <OverflowButton
                            after={visibilityData().text}
                            selected={visibilityMenuVisible()}
                            onClick={handleVisibilityMenu}
                        >
                            <Icon source={icons} id={visibilityData().icon} />
                        </OverflowButton>
                    </div>
                    <Button variant="accent" class="l-media__block">
                        {intl.formatMessage({ id: 'modal.compose.send', defaultMessage: 'Send' })}{' '}
                        <Icon source={icons} id="send" class="u-ml-50" />
                    </Button>
                </>
            }
            bodyClass="u-pb-0"
            flat
            onClose={handleClose}
        >
            <Form>
                <Show when={props.replyTo} keyed>
                    {(replyTo) => (
                        <Bubble class="u-b-1">
                            <TimelineNote note={replyTo} compact noFooter noLink noMenu noReplyContext />
                        </Bubble>
                    )}
                </Show>

                <FormItem>
                    <TextField
                        value={subject()}
                        placeholder="optional"
                        onInput={handleSetSubject}
                        before={
                            <>
                                <span class="u-pl-100 u-pr-100">
                                    {intl.formatMessage({ id: 'modal.compose.subject', defaultMessage: 'Subject' })}
                                </span>
                                <Divider variant="vertical" class="u-ml-50" />
                            </>
                        }
                    />
                </FormItem>

                <FormItem>
                    <TextArea
                        minLines={3}
                        value={text()}
                        onInput={handleSetText}
                        bottom={
                            <>
                                <div class="l-media__block l-media__block--main">
                                    <ActionButton
                                        quiet
                                        title={intl.formatMessage({
                                            id: 'modal.compose.attachments',
                                            defaultMessage: 'Attachments',
                                        })}
                                    >
                                        <Icon source={icons} id="image" />
                                    </ActionButton>{' '}
                                    <ActionButton
                                        quiet
                                        title={intl.formatMessage({
                                            id: 'modal.compose.emojis',
                                            defaultMessage: 'Emojis',
                                        })}
                                    >
                                        <Icon source={icons} id="smile" />
                                    </ActionButton>
                                </div>

                                <small class="l-media__block u-pl-200 u-pr-200">{3000 - text().length}</small>
                            </>
                        }
                    />
                </FormItem>
            </Form>
        </Dialog>
    );
};
