import { Exome } from 'exome';
import * as io from 'io-ts';

import { NoteC } from '@/entities/note';

import { Note } from './note';
import { Session } from './session';

export class NoteList extends Exome {
    notesById = new Map<string, Note>();

    constructor(readonly session: Session) {
        super();
    }

    private manualEmit() {
        /* noop */
    }

    add(data: io.TypeOf<typeof NoteC>) {
        const note = new Note(this.session, data);
        this.notesById.set(data.id, note);
        return note;
    }

    upsert = (data: io.TypeOf<typeof NoteC>) => {
        let note = this.notesById.get(data.id);

        if (note) {
            note.update(data);
        } else {
            note = this.add(data);
        }

        return note;
    };

    get = (id: string) => this.notesById.get(id);

    delete = (id: string) => {
        const note = this.get(id);

        if (note) {
            this.notesById.delete(id);
            note.markDeleted();
            this.manualEmit();
        }

        return note;
    };
}
