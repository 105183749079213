import { Component, JSX, mergeProps, splitProps } from 'solid-js';

export const Icon: Component<
    {
        source: string;
        id: string;
        large?: boolean;
        block?: boolean;
        class?: string;
    } & JSX.SvgSVGAttributes<SVGSVGElement>
> = (_props) => {
    const propsWithDefaults = mergeProps({ class: '' }, _props);
    const [props, propsRest] = splitProps(propsWithDefaults, ['source', 'id', 'large', 'block', 'class']);

    return (
        <svg
            class={`o-icon o-icon--${props.id} ${props.large ? 'o-icon--large' : ''} ${
                props.block ? 'u-d-block' : ''
            } ${props.class}`}
            width="1em"
            height="1em"
            aria-hidden="true"
            {...propsRest}
        >
            <use href={`${props.source}#${props.id}`} />
        </svg>
    );
};
