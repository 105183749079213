import { NavLink } from '@solidjs/router';
import { Root } from 'mdast-util-from-markdown/lib';
import { Component, ComponentProps, Match, Show, splitProps, Switch } from 'solid-js';
import { Dynamic } from 'solid-js/web';

import { ImageEmoji } from '@/iro/objects/Emoji';
import { Markdown } from '@/iro/objects/Markdown';
import { useStore } from '@/lib/exome/solid';
import { autolinkFromMarkdown } from '@/lib/mdast/autolink';
import { emojiFromMarkdown } from '@/lib/mdast/emojis';
import { hashtagFromMarkdown } from '@/lib/mdast/hashtags';
import { mentionFromMarkdown } from '@/lib/mdast/mentions';
import { isMatchingN } from '@/lib/ts-pattern/util';
import * as routes from '@/routes';
import { Session } from '@/store/session';

import { Emoji } from './Emoji';

export const ExtendedMarkdown: Component<{
    text: string | Root;
    customEmojis: Record<string, string>;
    originHost?: string;
    session: Session;
    noLinks?: boolean;
    noInlineImages?: boolean;
    emojiSize?: ComponentProps<typeof ImageEmoji>['size'];
}> = (_props) => {
    const [props, propsRest] = splitProps(_props, ['session', 'customEmojis', 'originHost', 'emojiSize']);
    const session = useStore(() => props.session);

    return (
        <Markdown
            mdastExtensions={[
                autolinkFromMarkdown(),
                emojiFromMarkdown(props.customEmojis),
                mentionFromMarkdown(props.originHost),
                hashtagFromMarkdown(),
            ]}
            {...propsRest}
        >
            {(el) => (
                <Switch>
                    <Match when={isMatchingN({ type: 'emoji' } as const, el)} keyed>
                        {(el) => <Emoji emoji={el.emoji} customEmojis={props.customEmojis} size={props.emojiSize} />}
                    </Match>

                    <Match when={isMatchingN({ type: 'mention' } as const, el)} keyed>
                        {(el) => (
                            <Dynamic
                                component={propsRest.noLinks ? 'span' : NavLink}
                                class="o-mention"
                                href={routes.user(session().id, `@${el.username}${el.host ? '@' + el.host : ''}`)}
                            >
                                @{el.username}
                                {el.host ? '@' + el.host : ''}
                            </Dynamic>
                        )}
                    </Match>

                    <Match when={isMatchingN({ type: 'hashtag' } as const, el)} keyed>
                        {(el) => (
                            <Show when={!propsRest.noLinks} fallback={<>#{el.tag}</>}>
                                <NavLink href={routes.hashtag(session().id, el.tag)}>#{el.tag}</NavLink>
                            </Show>
                        )}
                    </Match>
                </Switch>
            )}
        </Markdown>
    );
};
