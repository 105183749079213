import { Component, ComponentProps, mergeProps, splitProps } from 'solid-js';

import { OverflowButton } from '@/iro/objects/OverflowButton';

export const NoteInteractionButton: Component<{ count?: number } & ComponentProps<typeof OverflowButton>> = (
    _props,
) => {
    const propsWithDefaults = mergeProps({ class: '' } as const, _props);
    const [props, propsRest] = splitProps(propsWithDefaults, ['count', 'class']);

    return (
        <OverflowButton
            class={`c-note__interaction-btn ${props.class}`}
            after={props.count ? <small class="c-note__interaction-btn-count">{props.count}</small> : undefined}
            {...propsRest}
        />
    );
};
