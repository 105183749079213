import { Outlet } from '@solidjs/router';
import { Component } from 'solid-js';

import { Session } from '@/store/session';

import { Nav } from '../components/Nav';
import { Sidebar } from '../components/Sidebar';

export const SessionView: Component<{ session: Session }> = (props) => {
    return (
        <>
            <Nav session={props.session} />
            <Outlet />
            <Sidebar session={props.session} />
        </>
    );
};
