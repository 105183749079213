import * as io from 'io-ts';
import { DateFromISOString } from 'io-ts-types/DateFromISOString';

import { NoteC } from './note';
import { UserC } from './user';

export enum NotificationType {
    Follow = 'follow',
    Mention = 'mention',
    Reply = 'reply',
    Renote = 'renote',
    Quote = 'quote',
    Reaction = 'reaction',
    PollVote = 'pollVote',
    PollEnded = 'pollEnded',
    FollowRequestReceived = 'receiveFollowRequest',
    FollowRequestAccepted = 'followRequestAccepted',
    GroupInvited = 'groupInvited',
    App = 'app',
}

export const NotificationTypeC = io.union([
    io.literal(NotificationType.Follow),
    io.literal(NotificationType.Mention),
    io.literal(NotificationType.Reply),
    io.literal(NotificationType.Renote),
    io.literal(NotificationType.Quote),
    io.literal(NotificationType.Reaction),
    io.literal(NotificationType.PollVote),
    io.literal(NotificationType.PollEnded),
    io.literal(NotificationType.FollowRequestReceived),
    io.literal(NotificationType.FollowRequestAccepted),
    io.literal(NotificationType.GroupInvited),
    io.literal(NotificationType.App),
]);

export const NotificationBaseC = io.type({
    id: io.string,
    createdAt: DateFromISOString,
    isRead: io.boolean,
    type: NotificationTypeC,
});

export const NoteNotificationC = io.intersection([
    NotificationBaseC,
    io.type({
        type: io.literal(NotificationType.PollEnded),
        note: NoteC,
    }),
]);

export type NoteNotification = io.TypeOf<typeof NoteNotificationC>;

export const NoteUserNotificationC = io.intersection([
    NotificationBaseC,
    io.type({
        type: io.union([
            io.literal(NotificationType.Reply),
            io.literal(NotificationType.Mention),
            io.literal(NotificationType.Quote),
            io.literal(NotificationType.Renote),
        ]),
        user: UserC,
        userId: io.string,
        note: NoteC,
    }),
]);

export type NoteUserNotification = io.TypeOf<typeof NoteUserNotificationC>;

export const UserNotificationC = io.intersection([
    NotificationBaseC,
    io.type({
        type: io.union([
            io.literal(NotificationType.Follow),
            io.literal(NotificationType.FollowRequestAccepted),
            io.literal(NotificationType.FollowRequestReceived),
        ]),
        user: UserC,
        userId: io.string,
    }),
]);

export type UserNotification = io.TypeOf<typeof UserNotificationC>;

export const ReactionNotificationC = io.intersection([
    NotificationBaseC,
    io.type({
        type: io.literal(NotificationType.Reaction),
        user: UserC,
        userId: io.string,
        note: NoteC,
        reaction: io.string,
    }),
]);

export type ReactionNotification = io.TypeOf<typeof ReactionNotificationC>;

export const PollVoteNotificationC = io.intersection([
    NotificationBaseC,
    io.type({
        type: io.literal(NotificationType.PollVote),
        user: UserC,
        userId: io.string,
        note: NoteC,
        choice: io.number,
    }),
]);

export type PollVoteNotification = io.TypeOf<typeof PollVoteNotificationC>;

export const NotificationC = io.union([
    NoteUserNotificationC,
    NoteNotificationC,
    UserNotificationC,
    ReactionNotificationC,
    PollVoteNotificationC,
]);

export type Notification = io.TypeOf<typeof NotificationC>;
