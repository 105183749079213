import { findAndReplace, RegExpMatchObject } from 'mdast-util-find-and-replace';
import { CompileContext, Extension, Token } from 'mdast-util-from-markdown';
import { PhrasingContent, Root } from 'mdast-util-from-markdown/lib';

import { mentionRegex } from '@/services/mentions';

import { previous } from './util';

declare module 'mdast' {
    interface PhrasingContentMap {
        mention: { type: 'mention'; username: string; host?: string };
    }
}

export function mentionFromMarkdown(originHost?: string): Extension {
    const regexp = new RegExp(mentionRegex, 'g');

    return {
        transforms: [transformMentions],
        enter: {
            enterMention,
        },
        exit: {
            exitMention,
        },
    };

    function enterMention(this: CompileContext, token: Token) {
        this.enter({ type: 'mention', username: '' }, token);
    }

    function exitMention(this: CompileContext, token: Token) {
        this.exit(token);
    }

    function transformMentions(tree: Root) {
        findAndReplace(tree, [[regexp, findMention]]);
    }

    function findMention(
        _: string,
        username: string,
        host: string | undefined,
        match: RegExpMatchObject,
    ): PhrasingContent | false {
        if (!previous(match) || match.stack.some((m) => m.type === 'link')) {
            return false;
        }

        return { type: 'mention', username, host: host ?? originHost };
    }
}
