import { Component, mergeProps, Show } from 'solid-js';

import { Bubble } from '@/iro/objects/Bubble';
import { useStore } from '@/lib/exome/solid';
import { Note as NoteStore } from '@/store/note';

import { TimelineNote } from './Note';

export const Parents: Component<{ note: NoteStore; class?: string }> = (_props) => {
    const props = mergeProps({ class: '' }, _props);
    const note = useStore(() => props.note);

    return (
        <>
            <Show when={note().parent} keyed>
                {(parent) => <Parents class={props.class} note={parent} />}
            </Show>
            <Bubble class="t-up">
                <TimelineNote note={props.note} compact noReplyContext />
            </Bubble>
        </>
    );
};

export const ParentsOf: Component<{ note: NoteStore; class?: string }> = (_props) => {
    const props = mergeProps({ class: '' }, _props);
    const note = useStore(() => props.note);

    return (
        <Show when={note().parent} keyed>
            {(parent) => (
                <div class="l-context">
                    <Parents class={props.class} note={parent} />
                </div>
            )}
        </Show>
    );
};
