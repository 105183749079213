import { NavLink } from '@solidjs/router';
import { Component, Match, Switch } from 'solid-js';

import { useStore } from '@/lib/exome/solid';
import * as routes from '@/routes';
import { Note as NoteStore } from '@/store/note';

import { Emojified } from '../../objects/Emojified';
import { ExtendedMarkdown } from '../../objects/ExtendedMarkdown';

export const NotificationNote: Component<{ note: NoteStore }> = (props) => {
    const note = useStore(() => props.note);
    const link = () => routes.note(note().session.id, note().id);

    return (
        <div class="c-notification__content s-links s-links--invisible">
            <Switch fallback={<NavLink href={link()}>(no text)</NavLink>}>
                <Match when={note().cw} keyed>
                    {(cw) => (
                        <NavLink href={link()}>
                            <Emojified text={cw} emojis={note().emojis} />
                        </NavLink>
                    )}
                </Match>

                <Match when={note().text} keyed>
                    {(text) => (
                        <NavLink
                            href={link()}
                            class="c-notification__note s-blockquotes s-blockquotes--compact s-code s-lists s-lists--compact"
                        >
                            <ExtendedMarkdown
                                text={text}
                                customEmojis={note().emojis}
                                originHost={note().host}
                                session={note().session}
                                noLinks
                                noInlineImages
                            />
                        </NavLink>
                    )}
                </Match>
            </Switch>
        </div>
    );
};
