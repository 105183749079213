import { useIntl } from '@cookbook/solid-intl';
import { useParams } from '@solidjs/router';
import { Component, createEffect, createResource, Match, on, Show, Switch } from 'solid-js';

import { Bubble } from '@/iro/objects/Bubble';
import { ButtonLink } from '@/iro/objects/Button';
import { Divider } from '@/iro/objects/Divider';
import { Icon } from '@/iro/objects/Icon';
import { useStore } from '@/lib/exome/solid';
import { Note as NoteStore } from '@/store/note';
import { Session } from '@/store/session';
import icons from '#/icons.svg';

import { SingleNote } from '../components/Note';
import { ParentsOf } from '../components/NoteParents';
import { RepliesOf } from '../components/NoteReplies';
import { Header } from '../layouts/Header';
import { NoteCard } from '../objects/NoteCard';
import { ErrorView, LoadingView } from './Message';

export const MAX_THREAD_DEPTH = 6;

export const NoteNoteView: Component<{ note: NoteStore }> = (props) => {
    const note = useStore(() => props.note);
    const intl = useIntl();

    let scrollTarget!: HTMLElement;

    createResource(
        () => props.note,
        async (note) => {
            await Promise.all([
                note.fetchChildren({ depth: MAX_THREAD_DEPTH }),
                note.fetchParents(),
                note.fetchRenotes(),
            ]);
            window.scrollTo({ top: scrollTarget.getBoundingClientRect().top + window.pageYOffset - 60 });
        },
    );

    return (
        <>
            <Header>
                <div class="l-media__block l-media__block--main">
                    <NoteCard note={props.note} />
                </div>
                <div class="l-media__block">
                    <Show when={note().uri} keyed>
                        {(uri) => (
                            <ButtonLink outline href={uri} target="_blank">
                                {intl.formatMessage({
                                    id: 'view.note.header.view_original',
                                    defaultMessage: 'Original',
                                })}{' '}
                                <Icon source={icons} id="arrow-up-right" class="u-ml-50" />
                            </ButtonLink>
                        )}
                    </Show>
                </div>
            </Header>

            <div class="l-main">
                <div class="l-main__content l-main__content--narrow">
                    <Show when={note().parent} keyed>
                        <ParentsOf note={props.note} />
                        <Divider variant="faint" class="u-mt-400 u-mb-400">
                            {intl.formatMessage({ id: 'view.note.divider.top', defaultMessage: 'reply' })}
                        </Divider>
                    </Show>

                    <Bubble highlight class="t-up" ref={scrollTarget}>
                        <SingleNote note={props.note} highlight />
                    </Bubble>

                    <Show when={note().children.length}>
                        <Divider variant="faint" class="u-mt-400 u-mb-400 u-mt-400 u-mb-400">
                            {intl.formatMessage(
                                {
                                    id: 'view.note.divider.bottom',
                                    defaultMessage: `{n, plural,
                                        one {1 reply}
                                        other {# replies}
                                    }`,
                                },
                                { n: note().repliesCount },
                            )}
                        </Divider>
                        <RepliesOf note={props.note} />
                    </Show>
                </div>
            </div>
        </>
    );
};

export const NoteView: Component<{ session: Session }> = (props) => {
    const noteList = useStore(() => props.session.notes);
    const params = useParams();
    const note = () => noteList().get(params.noteId ?? '');

    const [noteReq, { refetch: refetchNote }] = createResource(async () => {
        if (params.noteId) {
            const note = await props.session.noteApi.show({ noteId: params.noteId });
            noteList().upsert(note);
        }
    });

    createEffect(on(() => params.noteId, refetchNote));

    return (
        <Switch>
            <Match when={note()} keyed>
                {(note) => <NoteNoteView note={note} />}
            </Match>
            <Match when={noteReq.loading}>
                <LoadingView />
            </Match>
            <Match when={noteReq.error} keyed>
                {(e) => <ErrorView>{JSON.stringify(e)}</ErrorView>}
            </Match>
        </Switch>
    );
};
