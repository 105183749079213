import { NavLink } from '@solidjs/router';
import { Component, mergeProps, Show } from 'solid-js';
import { Dynamic } from 'solid-js/web';

export const Avatar: Component<{
    name?: string;
    href?: string;
    image?: string;
    class?: string;
    size?: '200' | '150' | '100' | '75' | '50';
}> = (_props) => {
    const props = mergeProps({ size: '100', class: '' }, _props);
    const letters = () => props.name?.slice(0, props.size === '50' ? 1 : 2) ?? '';

    return (
        <Dynamic
            component={props.href ? NavLink : 'div'}
            href={props.href}
            class={`o-avatar o-avatar--${props.size} ${props.class}`}
        >
            <Show
                when={props.image}
                fallback={<div class="o-avatar__content o-avatar__content--colored">{letters()}</div>}
            >
                <img class="o-avatar__content" src={props.image} />
            </Show>
        </Dynamic>
    );
};
