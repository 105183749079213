import { Component, JSX, mergeProps, splitProps } from 'solid-js';

export const Table: Component<
    {
        box?: boolean;
        flush?: boolean;
        interactive?: boolean;
        fixed?: boolean;
        size?: '50' | '100';
    } & JSX.HTMLAttributes<HTMLTableElement>
> = (_props) => {
    const propsWithDefaults = mergeProps({ size: '100', class: '' }, _props);
    const [props, propsRest] = splitProps(propsWithDefaults, ['box', 'flush', 'interactive', 'fixed', 'size', 'class']);

    return (
        <table
            class={`o-table o-table--${props.size} ${props.box ? 'o-table--box' : ''} ${
                props.flush ? 'o-table--flush' : ''
            } ${props.interactive ? 'o-table--interactive' : ''} ${props.fixed ? 'o-table--fixed' : ''} ${props.class}`}
            {...propsRest}
        />
    );
};

export const TableHead: Component<JSX.HTMLAttributes<HTMLTableSectionElement>> = (_props) => {
    const propsWithDefaults = mergeProps({ class: '' }, _props);
    const [props, propsRest] = splitProps(propsWithDefaults, ['class']);

    return <thead class={`o-table__head ${props.class}`} {...propsRest} />;
};

export const TableHeadCell: Component<JSX.ThHTMLAttributes<HTMLTableCellElement>> = (_props) => {
    const propsWithDefaults = mergeProps({ class: '' }, _props);
    const [props, propsRest] = splitProps(propsWithDefaults, ['class']);

    return <th class={`o-table__head-cell ${props.class}`} {...propsRest} />;
};
export const TableBody: Component<JSX.HTMLAttributes<HTMLTableSectionElement>> = (_props) => {
    const propsWithDefaults = mergeProps({ class: '' }, _props);
    const [props, propsRest] = splitProps(propsWithDefaults, ['class']);

    return <tbody class={`o-table__body ${props.class}`} {...propsRest} />;
};

export const TableRow: Component<JSX.HTMLAttributes<HTMLTableRowElement>> = (_props) => {
    const propsWithDefaults = mergeProps({ class: '' }, _props);
    const [props, propsRest] = splitProps(propsWithDefaults, ['class']);

    return <tr class={`o-table__row ${props.class}`} {...propsRest} />;
};

export const TableCell: Component<{ divider?: boolean } & JSX.TdHTMLAttributes<HTMLTableCellElement>> = (_props) => {
    const propsWithDefaults = mergeProps({ class: '' }, _props);
    const [props, propsRest] = splitProps(propsWithDefaults, ['divider', 'class']);

    return (
        <td class={`o-table__cell ${props.divider ? 'o-table__cell--divider' : ''} ${props.class}`} {...propsRest} />
    );
};
