import { createSignal, useContext } from 'solid-js';

import { OnDemandContext } from '../objects/OnDemand';

export const useOnDemand = () => {
    const onDemand = useContext(OnDemandContext);

    const [visible, setVisible] = createSignal(false);

    const create: typeof onDemand = (children) => {
        if (visible()) {
            return () => void 0;
        }

        setVisible(true);
        const close = onDemand((close) =>
            children(() => {
                setVisible(false);
                close();
            }),
        );

        return () => {
            setVisible(false);
            close();
        };
    };

    return [create, visible] as const;
};
