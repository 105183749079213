import { useIntl } from '@cookbook/solid-intl';
import { Navigate, Route, Routes, useParams } from '@solidjs/router';
import { MatchFilters } from '@solidjs/router/dist/types';
import { Component, ErrorBoundary, Match, Show, Switch } from 'solid-js';

import { OnDemandProvider } from '@/iro/objects/OnDemand';
import { useStore } from '@/lib/exome/solid';
import * as routes from '@/routes';
import { Session } from '@/store/session';
import { sessionList as sessionList_ } from '@/store/sessionList';

import { HashtagsView } from './Hashtags';
import { ErrorView, LoadingView } from './Message';
import { NoteView } from './Note';
import { SessionView } from './Session';
import { SettingsView } from './Settings';
import { SetupView } from './Setup';
import { TimelineView } from './Timeline';
import { UserView } from './User';

const filters: MatchFilters = {
    session: (id) => !!sessionList_.get(id),
};

export const SessionWrapper: Component<{ component: Component<{ session: Session }> }> = (props) => {
    const params = useParams();
    const sessionList = useStore(() => sessionList_);
    const session = () => (params.session ? sessionList().get(params.session) : undefined);

    return (
        <Show when={session()} keyed>
            {(session) => <props.component session={session} />}
        </Show>
    );
};

export const RootView: Component = () => {
    const sessionList = useStore(() => sessionList_);
    const first = () => sessionList().first;
    const intl = useIntl();

    return (
        <ErrorBoundary
            fallback={
                <ErrorView>
                    {intl.formatMessage({
                        id: 'msg.unhandled_error',
                        defaultMessage: 'Unhandled error. Check the dev console for details.',
                    })}
                </ErrorView>
            }
        >
            <OnDemandProvider>
                <Switch>
                    <Match when={sessionList().loading}>
                        <LoadingView>
                            {intl.formatMessage({ id: 'msg.loading_sessions', defaultMessage: 'Loading sessions' })}
                        </LoadingView>
                    </Match>
                    <Match when={!first()}>
                        <Routes>
                            <Route path={routes.setup()} component={SetupView} />
                            <Route path="/*" element={<Navigate href={routes.setup()} />} />
                        </Routes>
                    </Match>
                    <Match when={first()} keyed>
                        {(first) => (
                            <Routes>
                                <Route path={routes.setup()} element={<Navigate href={routes.home(first.id)} />} />
                                <Route
                                    path="/:session"
                                    element={<SessionWrapper component={SessionView} />}
                                    matchFilters={filters}
                                >
                                    <Route path="/home" element={<SessionWrapper component={TimelineView} />} />
                                    <Route path="/user/:userId" element={<SessionWrapper component={UserView} />} />
                                    <Route path="/note/:noteId" element={<SessionWrapper component={NoteView} />} />
                                    <Route path="/tags" element={<SessionWrapper component={HashtagsView} />} />
                                    <Route path="/tags/:tag" element={<SessionWrapper component={HashtagsView} />} />
                                    <Route path="/settings" element={<SessionWrapper component={SettingsView} />} />
                                    <Route path="/*" element={<Navigate href="home" />} />
                                </Route>
                                <Route path="/*" element={<Navigate href={routes.home(first.id)} />} />
                            </Routes>
                        )}
                    </Match>
                </Switch>
            </OnDemandProvider>
        </ErrorBoundary>
    );
};
