import * as E from 'fp-ts/Either';
import * as io from 'io-ts';
import { reporter } from 'io-ts-reporters';
import { KyInstance } from 'ky/distribution/types/ky';

import { NotificationC } from '@/entities/notification';

export class NotificationApi {
    constructor(readonly owner: { client: KyInstance }) {}

    async fetch(params: {
        limit?: number;
        sinceId?: string;
        untilId?: string;
        following?: boolean;
        unreadOnly?: boolean;
        markAsRead?: boolean;
        includeTypes?: string[];
        excludeTypes?: string[];
    }) {
        const res = await this.owner.client.post('i/notifications', { json: params }).json();
        const notifications = io.array(NotificationC).decode(res);

        if (E.isLeft(notifications)) {
            throw reporter(notifications);
        }

        return notifications.right;
    }
}
