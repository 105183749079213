import { Link, LinkProps, NavLink, NavLinkProps } from '@solidjs/router';
import { Component, ComponentProps, JSX, mergeProps, Show, splitProps } from 'solid-js';

export const OverflowButton: Component<
    {
        block?: boolean;
        selected?: boolean;
        color?: 'red' | 'blue' | 'green';
        class?: string;
        before?: JSX.Element;
        after?: JSX.Element;
    } & JSX.ButtonHTMLAttributes<HTMLButtonElement>
> = (_props) => {
    const propsWithDefaults = mergeProps({ class: '' }, _props);
    const [props, propsRest] = splitProps(propsWithDefaults, [
        'block',
        'selected',
        'color',
        'class',
        'before',
        'after',
        'children',
    ]);

    return (
        <button
            class={`o-overflow-button ${props.block ? 'u-d-block' : ''} ${
                props.color ? `o-overflow-button--${props.color}` : ''
            } ${props.selected ? 'is-selected' : ''} ${props.class}`}
            {...propsRest}
        >
            <Show when={props.before !== undefined}>
                <span class="o-overflow-button__outside">{props.before}</span>
            </Show>
            <span class="o-overflow-button__inside">{props.children}</span>
            <Show when={props.after !== undefined}>
                <span class="o-overflow-button__outside">{props.after}</span>
            </Show>
        </button>
    );
};

export const OverflowLink: Component<ComponentProps<typeof OverflowButton> & LinkProps> = (_props) => {
    const propsWithDefaults = mergeProps({ class: '' }, _props);
    const [props, propsRest] = splitProps(propsWithDefaults, ['block', 'selected', 'class']);

    return (
        <Link
            class={`o-overflow-button ${props.block ? 'u-d-block' : ''} ${props.selected ? 'is-selected' : ''} ${
                props.class
            }`}
            {...propsRest}
        />
    );
};

export const OverflowNavLink: Component<ComponentProps<typeof OverflowButton> & NavLinkProps> = (_props) => {
    const propsWithDefaults = mergeProps({ class: '' }, _props);
    const [props, propsRest] = splitProps(propsWithDefaults, ['block', 'selected', 'class']);

    return (
        <NavLink
            class={`o-overflow-button ${props.block ? 'u-d-block' : ''} ${props.selected ? 'is-selected' : ''} ${
                props.class
            }`}
            {...propsRest}
        />
    );
};
