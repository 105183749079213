import { findAndReplace } from 'mdast-util-find-and-replace';
import { CompileContext, Extension, Token } from 'mdast-util-from-markdown';
import { PhrasingContent, Root } from 'mdast-util-from-markdown/lib';

import { emojiRegex } from '@/services/emojis';

declare module 'mdast' {
    interface PhrasingContentMap {
        emoji: { type: 'emoji'; emoji: string };
    }
}

export function emojiFromMarkdown(customEmojis: Record<string, string>): Extension {
    const regex = emojiRegex(customEmojis, 'g');

    return {
        transforms: [transformEmojis],
        enter: {
            enterEmoji,
        },
        exit: {
            exitEmoji,
        },
    };

    function enterEmoji(this: CompileContext, token: Token) {
        this.enter({ type: 'emoji', emoji: '' }, token);
    }

    function exitEmoji(this: CompileContext, token: Token) {
        this.exit(token);
    }

    function transformEmojis(tree: Root) {
        findAndReplace(tree, [[regex, findEmoji]]);
    }

    function findEmoji(emoji: string): PhrasingContent | false {
        return { type: 'emoji', emoji };
    }
}
