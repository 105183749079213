import { Component, JSX, mergeProps, splitProps } from 'solid-js';

export const Bubble: Component<{ size?: string; highlight?: boolean } & JSX.HTMLAttributes<HTMLElement>> = (_props) => {
    const propsWithDefaults = mergeProps({ size: '100', class: '' }, _props);
    const [props, propsRest] = splitProps(propsWithDefaults, ['size', 'highlight', 'class']);

    return (
        <article
            class={`o-bubble o-bubble--${props.size} ${props.highlight ? 'o-bubble--highlight' : ''} ${props.class}`}
            {...propsRest}
        />
    );
};
