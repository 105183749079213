import { Accessor, createEffect, createSignal, on } from 'solid-js';

function strToFloat(s: string, def: number) {
    const n = +s.slice(0, -2);
    return !isNaN(n) ? n : def;
}

export const useAutosize = (deps: Accessor<unknown>[], conf?: { min?: number; max?: number }) => {
    let el: HTMLTextAreaElement | undefined;

    const [height, setHeight] = createSignal('0px');

    const min = Math.max(conf?.min ?? 1, 1);
    const max = Math.max(conf?.max ?? Infinity, min);

    const update = () => {
        if (!el) {
            return;
        }

        const computedStyle = getComputedStyle(el);
        const lineHeight = strToFloat(computedStyle.lineHeight, 1);
        const border = strToFloat(computedStyle.borderTopWidth, 0) + strToFloat(computedStyle.borderBottomWidth, 0);
        const padding = strToFloat(computedStyle.paddingTop, 0) + strToFloat(computedStyle.paddingBottom, 0);
        const offset = border + padding;
        const minHeight = lineHeight * min + offset;

        const oldHeight = el.style.height || computedStyle.height;
        el.style.height = '0';
        const scrollHeight = el.scrollHeight;
        el.style.height = oldHeight;

        const contentRows = Math.ceil(Math.round(((scrollHeight - padding) / lineHeight) * 10) / 10);
        const rows = Math.min(Math.max(contentRows, min), max);
        const height = Math.max(Math.ceil(rows * lineHeight + offset), minHeight);

        setHeight(`${height}px`);
    };

    const mount = (el_: HTMLTextAreaElement) => {
        el = el_;
        update();
    };

    createEffect(on(deps, update));

    return [mount, height] as const;
};
