import { Component, ComponentProps, createEffect, mergeProps, splitProps } from 'solid-js';

import { useScrollTop } from '../hooks/scrollTop';
import { Button } from '../objects/Button';
import { Icon } from '../objects/Icon';

export const ScrollTopButton: Component<{ iconSource: string; watch: HTMLElement } & ComponentProps<typeof Button>> = (
    _props,
) => {
    const propsWithDefaults = mergeProps({ class: '' }, _props);
    const [props, propsRest] = splitProps(propsWithDefaults, ['watch', 'iconSource', 'class']);

    const [mount, show, scrollTop] = useScrollTop();

    createEffect(() => mount(props.watch));

    return (
        <Button
            class={`o-overlay-button ${show() ? 'is-visible' : ''} ${props.class}`}
            round
            onClick={scrollTop}
            {...propsRest}
        >
            <Icon source={props.iconSource} id="arrow-up" />
        </Button>
    );
};
