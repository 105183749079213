import { useIntl } from '@cookbook/solid-intl';
import { Component, Show } from 'solid-js';

import { IconNav, IconNavLink } from '@/iro/objects/IconNav';
import { useStore } from '@/lib/exome/solid';
import * as routes from '@/routes';
import { Session } from '@/store/session';
import { Avatar } from '@/ui/objects/Avatar';
import icons from '#/icons.svg';

export const Nav: Component<{ session: Session }> = (props) => {
    const session = useStore(() => props.session);
    const intl = useIntl();

    return (
        <IconNav vertical class="l-sidebar l-sidebar--nav u-themed t-hi">
            <Show when={session().self} keyed>
                {(self) => <Avatar user={self} class="u-mb-100" />}
            </Show>

            <IconNavLink
                icon={[icons, 'home']}
                href={routes.home(session().id)}
                title={intl.formatMessage({ id: 'component.nav.home', defaultMessage: 'Home' })}
            />

            <IconNavLink
                icon={[icons, 'hash']}
                href={routes.hashtags(session().id)}
                title={intl.formatMessage({ id: 'component.nav.tags', defaultMessage: 'Hashtags' })}
            />

            <IconNavLink
                icon={[icons, 'cloud']}
                href={routes.drive(session().id)}
                title={intl.formatMessage({ id: 'component.nav.drive', defaultMessage: 'Drive' })}
            />

            <IconNavLink
                icon={[icons, 'cog']}
                href={routes.settings(session().id)}
                title={intl.formatMessage({ id: 'component.nav.settings', defaultMessage: 'Settings' })}
                class="u-mt-auto"
            />
        </IconNav>
    );
};
