import * as E from 'fp-ts/Either';
import * as io from 'io-ts';
import { reporter } from 'io-ts-reporters';
import { KyInstance } from 'ky/distribution/types/ky';
import { match, P } from 'ts-pattern';

import { NoteC } from '@/entities/note';
import { User, UserC } from '@/entities/user';
import { JobInfo, JobQueue } from '@/lib/jobQueue';

import { mentionRegex } from '../mentions';

export class UserApi {
    private showQueue = new JobQueue({
        maxAge: 0,

        run: (jobs: JobInfo<string>[]) => {
            const userIds = jobs.map((job) => job.input);
            const req = (async () => {
                const res = await this.owner.client.post('users/show', { json: { userIds } }).json();
                const user = io.array(UserC).decode(res);

                if (E.isLeft(user)) {
                    throw reporter(user);
                }

                return user.right;
            })();

            return userIds.map((userId) =>
                req.then((results) => {
                    const result = results.find((user) => user.id === userId);

                    if (!result) {
                        throw new Error(`User ${userId} was requested, but not returned`);
                    }

                    return result;
                }),
            );
        },
    });

    constructor(readonly owner: { client: KyInstance }) {}

    private async showByUsername(params: { username: string; host?: string }) {
        const res = await this.owner.client.post('users/show', { json: params }).json();
        const user = UserC.decode(res);

        if (E.isLeft(user)) {
            throw reporter(user);
        }

        return user.right;
    }

    show(params: { userId: string } | { username: string; host?: string } | { userIdOrName: string }): Promise<User>;
    show(params: { userIds: string[] }): Promise<User[]>;
    async show(
        params:
            | { userId: string }
            | { username: string; host?: string }
            | { userIds: string[] }
            | { userIdOrName: string },
    ): Promise<User | User[]> {
        return match(params)
            .with({ userId: P._ }, (q) => this.showQueue.submit(q.userId))
            .with({ username: P._ }, this.showByUsername)
            .with({ userIds: P._ }, (q) => Promise.all(q.userIds.map(this.showQueue.submit)))
            .with({ userIdOrName: P._ }, (q) => {
                const match = q.userIdOrName.match(mentionRegex);
                return match
                    ? this.showByUsername({ username: match.groups!.username!, host: match.groups?.host })
                    : this.showQueue.submit(q.userIdOrName);
            })
            .run();
    }

    async self() {
        const res = await this.owner.client.post('i').json();
        const user = UserC.decode(res);

        if (E.isLeft(user)) {
            throw reporter(user);
        }

        return user.right;
    }

    async notes(params: {
        userId: string;
        includeReplies?: boolean;
        limit?: number;
        sinceId?: string;
        untilId?: string;
        sinceDate?: number;
        untilDate?: number;
        includeMyRenotes?: boolean;
        withFiles?: boolean;
        fileType?: string[];
        excludeNsfw?: boolean;
    }) {
        const res = await this.owner.client.post('users/notes', { json: params }).json();
        const notes = io.array(NoteC).decode(res);

        if (E.isLeft(notes)) {
            throw reporter(notes);
        }

        return notes.right;
    }
}
