import * as E from 'fp-ts/Either';
import * as io from 'io-ts';
import { reporter } from 'io-ts-reporters';
import { KyInstance } from 'ky/distribution/types/ky';

import { NoteC } from '@/entities/note';

export class NoteApi {
    constructor(readonly owner: { client: KyInstance }) {}

    async show(params: { noteId: string }) {
        const res = await this.owner.client.post('notes/show', { json: params }).json();
        const note = NoteC.decode(res);

        if (E.isLeft(note)) {
            throw new Error(reporter(note).join('\n\n'));
        }

        return note.right;
    }

    async timeline(params: {
        limit?: number;
        sinceId?: string;
        untilId?: string;
        sinceDate?: number;
        untilDate?: number;
        includeMyRenotes?: boolean;
        includeRenotedMyNotes?: boolean;
        includeLocalRenotes?: boolean;
        withFiles?: boolean;
    }) {
        const res = await this.owner.client.post('notes/timeline', { json: params }).json();
        const note = io.array(NoteC).decode(res);

        if (E.isLeft(note)) {
            throw reporter(note);
        }

        return note.right;
    }

    async children(params: { noteId: string; limit?: number; depth?: number; sinceId?: string; untilId?: string }) {
        const res = await this.owner.client.post('notes/children', { json: params }).json();
        const note = io.array(NoteC).decode(res);

        if (E.isLeft(note)) {
            throw reporter(note);
        }

        return note.right;
    }

    async conversation(params: { noteId: string; limit?: number; offset?: number }) {
        const res = await this.owner.client.post('notes/conversation', { json: params }).json();
        const note = io.array(NoteC).decode(res);

        if (E.isLeft(note)) {
            throw reporter(note);
        }

        return note.right;
    }

    async renotes(params: { noteId: string; limit?: number; sinceId?: string; untilId?: string }) {
        const res = await this.owner.client.post('notes/renotes', { json: params }).json();
        const note = io.array(NoteC).decode(res);

        if (E.isLeft(note)) {
            throw reporter(note);
        }

        return note.right;
    }
}
