import { useIntl } from '@cookbook/solid-intl';
import { NavLink } from '@solidjs/router';
import { getExomeId } from 'exome';
import { Component, createSignal, mergeProps, Show } from 'solid-js';

import { useCollapse } from '@/iro/hooks/collapsible';
import { ActionButton } from '@/iro/objects/ActionButton';
import { Icon } from '@/iro/objects/Icon';
import { useStore } from '@/lib/exome/solid';
import * as routes from '@/routes';
import { Note as NoteStore } from '@/store/note';
import { User } from '@/store/user';
import icons from '#/icons.svg';

import { Emojified } from '../objects/Emojified';
import { NoteContent } from './Note/NoteContent';
import { NoteContextHeader } from './Note/NoteContextHeader';
import { NoteFooter } from './Note/NoteFooter';
import { NoteHeader } from './Note/NoteHeader';

export const NoteContextUser: Component<{
    user: User;
}> = (props) => {
    const user = useStore(() => props.user);
    const link = () => routes.user(props.user.session.id, user().fullUsername);

    return (
        <strong class="u-c-muted">
            <NavLink href={link()}>
                <Emojified text={user().displayname} emojis={user().emojis} />
            </NavLink>
        </strong>
    );
};

export const NoteContext: Component<{
    note: NoteStore;
    pinned?: boolean;
    noReply?: boolean;
    referenceNoteId?: string;
}> = (props) => {
    const note = useStore(() => props.note);
    const intl = useIntl();

    const contentNoteStatic = () => {
        const n = note();
        return n.isRenote && n.renote ? n.renote : props.note;
    };
    const contentNote = useStore(contentNoteStatic);

    return (
        <>
            <Show when={props.pinned} keyed>
                <NoteContextHeader note={props.note} icon="thumbtack">
                    {intl.formatMessage({
                        id: 'component.note.context.pin',
                        defaultMessage: 'Pinned}',
                    })}
                </NoteContextHeader>
            </Show>

            <Show when={note().isRenote && note().renote} keyed>
                <NoteContextHeader note={props.note} icon="repeat">
                    {intl.formatMessage(
                        {
                            id: 'component.note.context.renote',
                            defaultMessage: 'Renoted by {user}',
                        },
                        {
                            user: <NoteContextUser user={note().user} />,
                        },
                    )}
                </NoteContextHeader>
            </Show>

            <Show when={!props.noReply && contentNote().parent} keyed>
                {(parent) => (
                    <NoteContextHeader note={parent} icon="arrow-corner-up-left" noTime>
                        {intl.formatMessage(
                            {
                                id: 'component.note.context.reply',
                                defaultMessage: 'Reply to {user}',
                            },
                            {
                                user: <NoteContextUser user={parent.user} />,
                            },
                        )}
                    </NoteContextHeader>
                )}
            </Show>

            <Show when={props.referenceNoteId && !note().isRenote && note().renoteId === props.referenceNoteId} keyed>
                <NoteContextHeader note={props.note} icon="quote" noTime>
                    {intl.formatMessage({
                        id: 'component.note.context.quote',
                        defaultMessage: 'Quote reply',
                    })}
                </NoteContextHeader>
            </Show>
        </>
    );
};

export const TimelineNote: Component<{
    note: NoteStore;
    compact?: boolean;
    pinned?: boolean;
    noFooter?: boolean;
    noLink?: boolean;
    noMenu?: boolean;
    noReplyContext?: boolean;
    referenceNoteId?: string;
    class?: string;
}> = (_props) => {
    const props = mergeProps({ class: '' } as const, _props);
    const note = useStore(() => props.note);

    const contentNoteStatic = () => {
        const n = note();
        return n.isRenote && n.renote ? n.renote : props.note;
    };
    const contentNote = useStore(contentNoteStatic);

    const collapseThreshold = () => (contentNote().cw ? undefined : props.compact ? 160 : 400);
    const [mountCollapsible, needsCollape, collapsed, setCollapsed] = useCollapse(
        () => getExomeId(props.note),
        collapseThreshold,
        50,
    );

    return (
        <div class={`c-note ${props.class}`}>
            <NoteContext note={props.note} pinned={props.pinned} noReply={props.noReplyContext} />

            <NoteHeader note={contentNoteStatic()} noLink={props.noLink} noMenu={props.noMenu}>
                <Show when={needsCollape()}>
                    <ActionButton round quiet onClick={[setCollapsed, (v: boolean) => !v]}>
                        <Icon source={icons} id={collapsed() ? 'chevron-down' : 'chevron-up'} />
                    </ActionButton>
                </Show>
            </NoteHeader>

            <Show when={contentNote().cw} keyed>
                {(cw) => (
                    <div class="c-note__title">
                        <Emojified text={cw} emojis={contentNote().emojis} />
                    </div>
                )}
            </Show>

            <div class={`c-note__collapsible ${collapsed() ? 'is-collapsed' : ''}`} ref={mountCollapsible}>
                <NoteContent note={contentNoteStatic()} referenceNoteId={props.referenceNoteId} />
            </div>

            <button class="c-note__collapse-indicator" onClick={[setCollapsed, false]} />

            <Show when={!props.noFooter}>
                <NoteFooter note={contentNoteStatic()} compact />
            </Show>
        </div>
    );
};

export const SingleNote: Component<{
    note: NoteStore;
    highlight?: boolean;
    class?: string;
}> = (_props) => {
    const props = mergeProps({ class: '' } as const, _props);
    const note = useStore(() => props.note);

    const contentNoteStatic = () => {
        const n = note();
        return n.isRenote && n.renote ? n.renote : props.note;
    };
    const contentNote = useStore(contentNoteStatic);

    const needsCollape = () => !!note().cw;
    const [collapsed, setCollapsed] = createSignal(true);

    return (
        <div class={`c-note ${props.class}`}>
            <NoteContext note={props.note} noReply />

            <NoteHeader note={contentNoteStatic()} size="150" noDate noLink>
                <Show when={needsCollape()}>
                    <ActionButton round quiet onClick={[setCollapsed, (v: boolean) => !v]}>
                        <Icon source={icons} id={collapsed() ? 'chevron-down' : 'chevron-up'} />
                    </ActionButton>
                </Show>
            </NoteHeader>

            <Show when={contentNote().cw} keyed>
                {(cw) => (
                    <div class="c-note__title">
                        <Emojified text={cw} emojis={contentNote().emojis} />
                    </div>
                )}
            </Show>

            <div class={`c-note__collapsible ${needsCollape() && collapsed() ? 'u-d-none is-collapsed' : ''}`}>
                <NoteContent note={contentNoteStatic()} large />
            </div>

            <button class="c-note__collapse-indicator" onClick={[setCollapsed, false]} />

            <NoteFooter note={contentNoteStatic()} />
        </div>
    );
};
