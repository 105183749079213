import { NavLink } from '@solidjs/router';
import { mergeProps, ParentComponent, Show } from 'solid-js';

import { useStore } from '@/lib/exome/solid';
import * as routes from '@/routes';
import { User } from '@/store/user';
import { Avatar } from '@/ui/objects/Avatar';

import { Emojified } from './Emojified';

export const UserCard: ParentComponent<{
    user: User;
    class?: string;
    avatar?: '200' | '150' | '100' | '75' | '50';
}> = (_props) => {
    const props = mergeProps({ class: '' } as const, _props);
    const user = useStore(() => props.user);
    const link = () => routes.user(props.user.session.id, user().fullUsername);

    return (
        <>
            <Show when={props.avatar} keyed>
                <Avatar user={props.user} size={props.avatar} class="l-media__block" />
            </Show>
            <div class="l-media__block l-media__block--main s-links s-links--invisible">
                <strong class="u-d-block u-elp">
                    <NavLink href={link()}>
                        <Emojified text={user().displayname} emojis={user().emojis} />
                    </NavLink>
                </strong>{' '}
                <div class="u-d-block u-elp">
                    <small>
                        @{user().username}
                        <Show when={user().host}>@{user().host}</Show>
                        {props.children}
                    </small>
                </div>
            </div>
        </>
    );
};
