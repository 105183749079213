import { useIntl } from '@cookbook/solid-intl';
import { Component, ComponentProps, Show, splitProps } from 'solid-js';

import { ActionMenu, ActionMenuItem, ActionMenuLink } from '@/iro/objects/ActionMenu';
import { useStore } from '@/lib/exome/solid';
import * as routes from '@/routes';
import { Note } from '@/store/note';
import icons from '#/icons.svg';

export const NoteMenu: Component<{ note: Note } & ComponentProps<typeof ActionMenu>> = (_props) => {
    const [props, propsRest] = splitProps(_props, ['note']);
    const note = useStore(() => props.note);
    const intl = useIntl();

    const copyLink = () => navigator.clipboard.writeText(note().uri ?? routes.note(note().session.id, note().id));

    const close = (fn?: () => void) => {
        fn?.();
        propsRest.onClose?.();
    };

    return (
        <ActionMenu {...propsRest}>
            <Show when={note().uri} keyed>
                {(uri) => (
                    <ActionMenuLink
                        preIcon={[icons, 'arrow-up-right']}
                        href={uri}
                        target="_blank"
                        onClick={propsRest.onClose}
                    >
                        {intl.formatMessage({
                            id: 'component.note.menu.view_original',
                            defaultMessage: 'View original',
                        })}
                    </ActionMenuLink>
                )}
            </Show>
            <ActionMenuItem preIcon={[icons, 'link']} onClick={[close, copyLink]}>
                {intl.formatMessage({ id: 'component.note.menu.copy_link', defaultMessage: 'Copy link' })}
            </ActionMenuItem>
            <ActionMenuItem preIcon={[icons, 'message-slash']}>
                {intl.formatMessage({ id: 'component.note.menu.mute_thread', defaultMessage: 'Mute thread' })}
            </ActionMenuItem>
        </ActionMenu>
    );
};
