import { Exome } from 'exome';
import * as A from 'fp-ts/Array';
import * as F from 'fp-ts/function';
import * as io from 'io-ts';
import { fromMarkdown } from 'mdast-util-from-markdown';
import { Root } from 'mdast-util-from-markdown/lib';

import { DriveFileC } from '@/entities/driveFile';
import { NoteC, NoteVisibility } from '@/entities/note';
import { autolinkFromMarkdown } from '@/lib/mdast/autolink';
import { emojiFromMarkdown } from '@/lib/mdast/emojis';
import { hashtagFromMarkdown } from '@/lib/mdast/hashtags';
import { mentionFromMarkdown } from '@/lib/mdast/mentions';
import { countReactions } from '@/services/reactions';

import { Session } from './session';
import { User } from './user';

export class Note extends Exome {
    id: string;
    createdAt: Date;
    user: User;
    text?: Root;
    cw?: string;
    parentId?: string;
    parent?: Note;
    renoteId?: string;
    renote?: Note;
    visibility: NoteVisibility;
    emojis: Record<string, string> = {};
    reactions: Record<string, number> = {};
    renotes: Note[] = [];
    files: io.TypeOf<typeof DriveFileC>[] = [];
    renoteCount = 0;
    repliesCount = 0;
    myReaction?: string;
    uri?: string;

    children: Note[] = [];

    deleted = false;

    constructor(readonly session: Session, data: io.TypeOf<typeof NoteC>) {
        super();

        this.id = data.id;
        this.createdAt = data.createdAt;
        this.user = session.users.upsert(data.user);
        this.visibility = data.visibility;
        this.update(data);
    }

    get isRenote() {
        return this.renote && !this.text && !this.cw && !this.files.length;
    }

    get reactionsCount() {
        return countReactions(this.reactions);
    }

    get host() {
        return this.user.host;
    }

    update(data: Partial<io.TypeOf<typeof NoteC>>) {
        if (data.id !== undefined) {
            this.id = data.id;
        }
        if (data.createdAt !== undefined) {
            this.createdAt = data.createdAt;
        }
        if (data.user !== undefined) {
            this.user = this.session.users.upsert(data.user);
        }
        if (data.cw !== undefined) {
            this.cw = data.cw ?? undefined;
        }
        if (data.replyId !== undefined) {
            this.parentId = data.replyId ?? undefined;
        }
        if (data.replyId === null) {
            this.parent = undefined;
        } else if (data.reply !== undefined) {
            this.setParents([data.reply]);
        }
        if (data.renoteId !== undefined) {
            this.renoteId = data.renoteId ?? undefined;
        }
        if (data.renoteId === null) {
            this.renote = undefined;
        } else if (data.renote !== undefined) {
            this.renote = this.session.notes.upsert(data.renote);
        }
        if (data.visibility !== undefined) {
            this.visibility = data.visibility;
        }
        if (data.emojis !== undefined) {
            this.emojis = data.emojis.reduce((r, emoji) => ({ ...r, [`:${emoji.name}:`]: emoji.url }), {});
        }
        if (data.text !== undefined || data.emojis !== undefined) {
            this.text = data.text
                ? fromMarkdown(data.text, 'utf8', {
                      mdastExtensions: [
                          autolinkFromMarkdown(),
                          emojiFromMarkdown(this.emojis),
                          mentionFromMarkdown(this.host),
                          hashtagFromMarkdown(),
                      ],
                  })
                : undefined;
        }
        if (data.files !== undefined) {
            this.files = data.files;
        }
        if (data.reactions !== undefined) {
            this.reactions = data.reactions;
        }
        if (data.renoteCount !== undefined) {
            this.renoteCount = data.renoteCount;
        }
        if (data.repliesCount !== undefined) {
            this.repliesCount = data.repliesCount;
        }
        if (data.myReaction !== undefined) {
            this.myReaction = data.myReaction ?? undefined;
        }
        if (data.uri !== undefined) {
            this.uri = data.uri ?? undefined;
        }
    }

    setParents(parents: io.TypeOf<typeof NoteC>[]) {
        const [parent, ...rest] = parents;
        if (parent) {
            this.parent = this.session.notes.upsert(parent);
            this.parent.setParents(rest);
        }
    }

    setChildren(children: io.TypeOf<typeof NoteC>[]) {
        const { left, right } = F.pipe(
            children,
            A.partition((note) => note.replyId === this.id || note.renoteId === this.id),
        );
        this.children = right.map(this.session.notes.upsert);

        for (const note of this.children) {
            note.setChildren(left);
        }
    }

    setRenotes(notes: io.TypeOf<typeof NoteC>[]) {
        this.renotes = notes.map(this.session.notes.upsert);
    }

    fetch = async () => {
        const note = await this.session.noteApi.show({ noteId: this.id });
        this.update(note);
    };

    fetchParents = async (params?: { limit?: number; offset?: number }) => {
        const notes = await this.session.noteApi.conversation({ ...params, noteId: this.id });
        if (notes.length) {
            this.setParents(notes);
        }
    };

    fetchChildren = async (params?: { limit?: number; depth?: number; sinceId?: string; untilId?: string }) => {
        const notes = await this.session.noteApi.children({ ...params, noteId: this.id });
        if (notes.length) {
            this.setChildren(notes);
        }
    };

    fetchRenotes = async (params?: { limit?: number; sinceId?: string; untilId?: string }) => {
        const notes = await this.session.noteApi.renotes({ ...params, noteId: this.id });
        if (notes.length) {
            this.setRenotes(notes);
        }
    };

    markDeleted() {
        this.deleted = true;
    }
}
