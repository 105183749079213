export function getScrollContainer(el: HTMLElement | null): HTMLElement | null {
    if (el === null || el.tagName === 'HTML') {
        return null;
    }

    const overflow = window.getComputedStyle(el).getPropertyValue('overflow-y');

    if (overflow === 'scroll' || overflow === 'auto') {
        return el;
    } else {
        return getScrollContainer(el.parentElement);
    }
}
