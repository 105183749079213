import { useIntl } from '@cookbook/solid-intl';
import { NavLink } from '@solidjs/router';
import { ParentComponent, Show } from 'solid-js';

import { Icon } from '@/iro/objects/Icon';
import { RelativeTime } from '@/iro/objects/RelativeTime';
import { useStore } from '@/lib/exome/solid';
import * as routes from '@/routes';
import { Note as NoteStore } from '@/store/note';
import icons from '#/icons.svg';

export const NoteContextHeader: ParentComponent<{
    note: NoteStore;
    icon: string;
    noTime?: boolean;
}> = (props) => {
    const note = useStore(() => props.note);
    const intl = useIntl();

    return (
        <header class="c-note__header">
            <div class="l-media l-media--flush">
                <NavLink class="u-c-muted" href={routes.note(props.note.session.id, note().id)}>
                    <Icon source={icons} id={props.icon} class="l-media__block" />
                </NavLink>

                <small class="l-media__block l-media__block--main u-elp s-links s-links--invisible">
                    {props.children}
                    <Show when={!props.noTime}>
                        <span class="u-ml-50 u-mr-50"> · </span>
                        <RelativeTime date={note().createdAt} locale={intl.locale} />
                    </Show>
                </small>
            </div>
        </header>
    );
};
