import * as A from 'fp-ts/Array';
import * as B from 'fp-ts/boolean';
import * as N from 'fp-ts/number';
import * as O from 'fp-ts/Ord';

import { impl, Variant } from '@/lib/union-types';

import { customEmojiRegex } from './emojis';

export type Reaction =
    | Variant<'Unicode', { emoji: string; count: number; highlight: boolean }>
    | Variant<'Custom', { name: string; url: string; count: number; highlight: boolean }>;

export const Reaction = impl<Reaction>();

export const ReactionOrds = [
    O.contramap((item: Reaction) => item.value.highlight)(B.Ord),
    O.contramap((item: Reaction) => item.value.count)(N.Ord),
];

export function renderReactions(
    reactions: Record<string, number>,
    emojis: Record<string, string>,
    highlight: string[] = [],
) {
    const regex = customEmojiRegex(emojis);

    let items: Reaction[] = [];

    for (const [key, count] of Object.entries(reactions)) {
        const hilight = highlight.includes(key);

        if (regex.test(key)) {
            const emoji = emojis[key];

            if (emoji) {
                items.push(Reaction.Custom({ name: key, url: emoji, count, highlight: hilight }));
            }
        } else {
            items.push(Reaction.Unicode({ emoji: key, count, highlight: hilight }));
        }
    }

    items = A.sortBy(ReactionOrds)(items);

    return items;
}

export function countReactions(reactions: Record<string, number>) {
    return Object.values(reactions).reduce((sum, n) => sum + n, 0);
}
