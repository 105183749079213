import { findAndReplace, RegExpMatchObject } from 'mdast-util-find-and-replace';
import { CompileContext, Extension, Token } from 'mdast-util-from-markdown';
import { PhrasingContent, Root } from 'mdast-util-from-markdown/lib';

import { hashtagRegex } from '@/services/hashtags';

import { previous } from './util';

declare module 'mdast' {
    interface PhrasingContentMap {
        hashtag: { type: 'hashtag'; tag: string };
    }
}

export function hashtagFromMarkdown(): Extension {
    const regexp = new RegExp(hashtagRegex, 'g');

    return {
        transforms: [transformHashtags],
        enter: {
            enterHashtag,
        },
        exit: {
            exitHashtag,
        },
    };

    function enterHashtag(this: CompileContext, token: Token) {
        this.enter({ type: 'hashtag', tag: '' }, token);
    }

    function exitHashtag(this: CompileContext, token: Token) {
        this.exit(token);
    }

    function transformHashtags(tree: Root) {
        findAndReplace(tree, [[regexp, findHashtag]]);
    }

    function findHashtag(_: string, tag: string, match: RegExpMatchObject): PhrasingContent | false {
        if (!previous(match) || match.stack.some((m) => m.type === 'link')) {
            return false;
        }

        return { type: 'hashtag', tag };
    }
}
