import { Component } from 'solid-js';

export const Time: Component<{
    date: Date;
    format?: Intl.DateTimeFormatOptions;
    class?: string;
    locale?: string | string[];
}> = (props) => {
    const date = () =>
        props.date.toLocaleString(
            props.locale,
            props.format ?? {
                minute: '2-digit',
                hour: '2-digit',
                month: 'long',
                day: 'numeric',
                year: 'numeric',
            },
        );

    return (
        <time class={props.class} dateTime={props.date.toISOString()} title={date()}>
            {date()}
        </time>
    );
};
