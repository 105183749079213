import { Component, JSX, mergeProps, splitProps } from 'solid-js';
import { Dynamic } from 'solid-js/web';

export const Heading: Component<
    {
        level: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
        tag?: string;
        class?: string;
    } & JSX.HTMLAttributes<HTMLHeadingElement>
> = (_props) => {
    const [props, propsRest] = splitProps(_props, ['level', 'tag', 'class']);

    return (
        <Dynamic
            component={props.tag}
            class={`o-heading o-heading--${props.level} ${props.class ?? ''}`}
            {...propsRest}
        />
    );
};

export const H1: Component<
    { level?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl'; class?: string } & JSX.HTMLAttributes<HTMLHeadingElement>
> = (_props) => {
    const props = mergeProps({ level: 'xxl' } as const, _props);
    return <Heading tag="h1" {...props} />;
};

export const H2: Component<
    { level?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl'; class?: string } & JSX.HTMLAttributes<HTMLHeadingElement>
> = (_props) => {
    const props = mergeProps({ level: 'xl' } as const, _props);
    return <Heading tag="h2" {...props} />;
};

export const H3: Component<
    { level?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl'; class?: string } & JSX.HTMLAttributes<HTMLHeadingElement>
> = (_props) => {
    const props = mergeProps({ level: 'lg' } as const, _props);
    return <Heading tag="h3" {...props} />;
};

export const H4: Component<
    { level?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl'; class?: string } & JSX.HTMLAttributes<HTMLHeadingElement>
> = (_props) => {
    const props = mergeProps({ level: 'md' } as const, _props);
    return <Heading tag="h4" {...props} />;
};

export const H5: Component<
    { level?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl'; class?: string } & JSX.HTMLAttributes<HTMLHeadingElement>
> = (_props) => {
    const props = mergeProps({ level: 'sm' } as const, _props);
    return <Heading tag="h5" {...props} />;
};

export const H6: Component<
    { level?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl'; class?: string } & JSX.HTMLAttributes<HTMLHeadingElement>
> = (_props) => {
    const props = mergeProps({ level: 'xs' } as const, _props);
    return <Heading tag="h6" {...props} />;
};
