import { useIntl } from '@cookbook/solid-intl';
import { Component, ComponentProps, For } from 'solid-js';

import { NoteVisibility } from '@/entities/note';
import { noteVisibilityIcon, noteVisibilityMsg } from '@/i18n/noteVisibility';
import { ActionMenu, ActionMenuItem } from '@/iro/objects/ActionMenu';
import icons from '#/icons.svg';

export const NoteVisibilityMenu: Component<
    { onClose?: (visibility?: NoteVisibility) => void } & Omit<ComponentProps<typeof ActionMenu>, 'onClose'>
> = (props) => {
    const intl = useIntl();

    const handleClose = async (result?: NoteVisibility) => {
        props.onClose?.(result);
    };

    return (
        <ActionMenu {...props}>
            <For each={Object.values(NoteVisibility)}>
                {(visibility) => (
                    <ActionMenuItem
                        preIcon={[icons, noteVisibilityIcon[visibility]]}
                        onClick={[handleClose, visibility]}
                    >
                        {intl.formatMessage(noteVisibilityMsg[visibility].label)}
                    </ActionMenuItem>
                )}
            </For>
        </ActionMenu>
    );
};
