import { Component } from 'solid-js';

import { Session } from '@/store/session';

import { Header } from '../layouts/Header';

export const HashtagsView: Component<{ session: Session }> = () => {
    return (
        <Header>
            <div class="l-media__block l-media__block--main">
                <strong>Hashtags</strong>
            </div>
        </Header>
    );
};
