import { useParams } from '@solidjs/router';
import { Component, createEffect, createResource, Match, on, Switch } from 'solid-js';

import { useStore } from '@/lib/exome/solid';
import { Session } from '@/store/session';

import { Header } from '../layouts/Header';
import { UserCard } from '../objects/UserCard';
import { ErrorView, LoadingView } from './Message';

export const UserView: Component<{ session: Session }> = (props) => {
    const userList = useStore(() => props.session.users);
    const params = useParams();
    const user = () => userList().get(params.userId ?? '');

    const [userReq, { refetch }] = createResource(async () => {
        try {
            if (!user() && params.userId) {
                const user = await props.session.userApi.show({ userIdOrName: params.userId });
                userList().upsert(user);
            }
        } catch (e) {
            console.error(e);
        }
    });

    createEffect(on(() => params.userId, refetch));

    return (
        <Switch>
            <Match when={user()} keyed>
                {(user) => (
                    <Header>
                        <UserCard user={user} avatar="100" />
                    </Header>
                )}
            </Match>
            <Match when={userReq.loading}>
                <LoadingView />
            </Match>
            <Match when={userReq.error} keyed>
                {(e) => <ErrorView>{JSON.stringify(e)}</ErrorView>}
            </Match>
        </Switch>
    );
};
