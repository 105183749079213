export class Mutex {
    private queue: (() => void)[] = [];
    private busy = 0;

    lock() {
        const isLocked = this.busy > 0;
        const lock = new Promise<void>((resolve) => (!isLocked ? resolve() : this.queue.push(resolve)));
        ++this.busy;
        return lock;
    }

    release() {
        const resolve = this.queue.shift();
        this.busy--;
        resolve?.();
    }
}
