import { defineMessages, MessageDescriptor, useIntl } from '@cookbook/solid-intl';
import { NavLink } from '@solidjs/router';
import { Component, Show } from 'solid-js';
import { P } from 'ts-pattern';

import { NotificationType } from '@/entities/notification';
import { Icon } from '@/iro/objects/Icon';
import { RelativeTime } from '@/iro/objects/RelativeTime';
import { useStore } from '@/lib/exome/solid';
import { isMatchingN } from '@/lib/ts-pattern/util';
import * as routes from '@/routes';
import { NoteNotification as NoteNotificationStore } from '@/store/notification/note';
import { NoteUserNotification as NoteUserNotificationStore } from '@/store/notification/noteUser';
import { PollVoteNotification as PollVoteNotificationStore } from '@/store/notification/pollVote';
import { ReactionNotification as ReactionNotificationStore } from '@/store/notification/reaction';
import { UserNotification as UserNotificationStore } from '@/store/notification/user';
import { User } from '@/store/user';
import { Avatar } from '@/ui/objects/Avatar';
import icons from '#/icons.svg';

import { Emoji } from '../objects/Emoji';
import { Emojified } from '../objects/Emojified';
import { NotificationNote } from './Notification/NotificationNote';

export const notificationIcon: Record<NotificationType, string> = {
    [NotificationType.Reply]: 'arrow-corner-up-left',
    [NotificationType.Quote]: 'quote',
    [NotificationType.Mention]: 'at',
    [NotificationType.Renote]: 'repeat',
    [NotificationType.Reaction]: 'smile',
    [NotificationType.PollVote]: 'poll-h',
    [NotificationType.PollEnded]: 'poll-h',
    [NotificationType.Follow]: 'user-plus',
    [NotificationType.FollowRequestAccepted]: 'user-check',
    [NotificationType.FollowRequestReceived]: 'user',
    [NotificationType.GroupInvited]: 'users',
    [NotificationType.App]: '',
};

export const notificationMsg = defineMessages({
    [NotificationType.Reply]: {
        id: 'component.notification.reply',
        defaultMessage: '{user} replied',
    },
    [NotificationType.Quote]: {
        id: 'component.notification.quote',
        defaultMessage: '{user} quoted',
    },
    [NotificationType.Mention]: {
        id: 'component.notification.mention',
        defaultMessage: '{user} mentioned you',
    },
    [NotificationType.Renote]: {
        id: 'component.notification.renote',
        defaultMessage: '{user} renoted',
    },
    [NotificationType.Reaction]: {
        id: 'component.notification.reaction',
        defaultMessage: '{user} reacted',
    },
    [NotificationType.PollVote]: {
        id: 'component.notification.poll_vote',
        defaultMessage: '{user} voted',
    },
    [NotificationType.PollEnded]: {
        id: 'component.notification.poll_ended',
        defaultMessage: '<b>A poll</b> ended',
    },
    [NotificationType.Follow]: {
        id: 'component.notification.follow',
        defaultMessage: '{user} followed you',
    },
    [NotificationType.FollowRequestAccepted]: {
        id: 'component.notification.follow_request_accepted',
        defaultMessage: '{user} accepted your follow request',
    },
    [NotificationType.FollowRequestReceived]: {
        id: 'component.notification.follow_request_received',
        defaultMessage: '{user} sent a follow request',
    },
    [NotificationType.GroupInvited]: {
        id: 'component.notification.group_invited',
        defaultMessage: 'UNSUPPORTED',
    },
    [NotificationType.App]: {
        id: 'component.notification.app',
        defaultMessage: 'UNSUPPORTED',
    },
}) satisfies Record<NotificationType, MessageDescriptor>;

export const NotificationUser: Component<{
    user: User;
}> = (props) => {
    const user = useStore(() => props.user);
    const link = () => routes.user(props.user.session.id, user().fullUsername);

    return (
        <strong>
            <NavLink href={link()}>
                <Emojified text={user().displayname} emojis={user().emojis} />
            </NavLink>
        </strong>
    );
};

export const Notification: Component<{
    notification:
        | NoteNotificationStore
        | NoteUserNotificationStore
        | UserNotificationStore
        | ReactionNotificationStore
        | PollVoteNotificationStore;
    class?: string;
}> = (props) => {
    const notification = useStore(() => props.notification);
    const note = () => {
        const n = notification();
        return 'note' in n ? n.note : undefined;
    };
    const user = () => {
        const n = notification();
        return 'user' in n ? n.user : note()?.user;
    };
    const intl = useIntl();

    return (
        <div
            class={`c-notification ${props.class}`}
            id={`notification-${notification().id}`}
            data-notification-id={notification().id}
        >
            <Show
                when={isMatchingN(P.instanceOf(ReactionNotificationStore), notification())}
                keyed
                fallback={
                    <Icon source={icons} id={notificationIcon[notification().type]} class="c-notification__icon" />
                }
            >
                {(notification) => (
                    <Emoji emoji={notification.reaction} customEmojis={note()?.emojis} class="c-notification__icon" />
                )}
            </Show>

            <header class="c-notification__header l-media l-media--flush l-media--75">
                <Show when={user()} keyed>
                    {(user) => <Avatar user={user} size="50" />}
                </Show>

                <div class="l-media__block l-media__block--main u-elp s-links s-links--invisible">
                    {intl.formatMessage(notificationMsg[notification().type], {
                        user: (
                            <Show when={user()} keyed>
                                {(user) => <NotificationUser user={user} />}
                            </Show>
                        ),
                    })}
                </div>
            </header>

            <RelativeTime date={notification().createdAt} locale={intl.locale} class="c-notification__date u-c-muted" />

            <Show when={note()} keyed>
                {(note) => <NotificationNote note={note} />}
            </Show>
        </div>
    );
};
