import { Exome } from 'exome';

import { NotificationType, UserNotification as UserNotificationE } from '@/entities/notification';

import { Session } from '../session';
import { User } from '../user';

export class UserNotification extends Exome {
    id: string;
    createdAt: Date;
    isRead: boolean;
    type: NotificationType.Follow | NotificationType.FollowRequestAccepted | NotificationType.FollowRequestReceived;
    user: User;

    constructor(readonly session: Session, data: UserNotificationE) {
        super();

        this.id = data.id;
        this.createdAt = data.createdAt;
        this.isRead = data.isRead;
        this.type = data.type;
        this.user = this.session.users.upsert(data.user);
        this.update(data);
    }

    update(data: Partial<UserNotificationE>) {
        if (data.id !== undefined) {
            this.id = data.id;
        }
        if (data.createdAt !== undefined) {
            this.createdAt = data.createdAt;
        }
        if (data.isRead !== undefined) {
            this.isRead = data.isRead;
        }
        if (data.type !== undefined) {
            this.type = data.type;
        }
        if (data.user !== undefined) {
            this.user = this.session.users.upsert(data.user);
        }
    }
}
