import * as dateFn from 'date-fns';
import * as A from 'fp-ts/Array';

import { Mutex } from './mutex';
import { delay } from './promise';
import { QueryPromise, queryPromise } from './queryPromise';

export interface BackoffStrategy {
    (attempt: number): number;
}

export interface Options<I, O> {
    autostart?: boolean;
    maxAge?: number;
    maxAttempts?: number;
    maxConcurrency?: number;
    backoff?: BackoffStrategy;
    tickDelay?: number;
    cacheKey?(input: I): string;
    runOne?(job: JobInfo<I>): Promise<O>;
    run?(jobs: JobInfo<I>[]): Promise<O>[];
}

export interface CacheItem<T> {
    readonly validUntil: Date;
    readonly task: QueryPromise<T>;
}

export interface JobInfo<I> {
    readonly input: I;
    attempt: number;
    maxAttempts: number;
}

export interface Job<I, O> extends JobInfo<I> {
    readonly resolve: (result: O) => void;
    readonly reject: (reason?: any) => void;
}

export class JobQueue<I, O> {
    readonly maxAge = dateFn.milliseconds({ hours: 1 });
    readonly maxAttempts: number = 1;
    readonly maxConcurrency: number = Number.POSITIVE_INFINITY;
    readonly backoff = createExponentialBackoff();
    readonly tickDelay: number = 10;
    readonly queueMutex = new Mutex();
    readonly cache = new Map<string, CacheItem<O>>();
    readonly queue: Job<I, O>[] = [];
    stopped = false;

    constructor(options?: Options<I, O>) {
        if (options?.maxAge) {
            this.maxAge = options.maxAge;
        }
        if (options?.maxAttempts) {
            this.maxAttempts = options.maxAttempts;
        }
        if (options?.maxConcurrency) {
            this.maxConcurrency = options.maxConcurrency;
        }
        if (options?.backoff) {
            this.backoff = options.backoff;
        }
        if (options?.tickDelay) {
            this.tickDelay = options.tickDelay;
        }
        if (options?.cacheKey) {
            this.cacheKey = options.cacheKey.bind(this);
        }
        if (options?.runOne) {
            this.runOne = options.runOne.bind(this);
        }
        if (options?.run) {
            this.run = options.run.bind(this);
        }

        if (options?.autostart ?? true) {
            this.loop();
        }
    }

    private enqueue(job: Job<I, O>) {
        this.queue.push(job);
        this.queueMutex.release();
    }

    pruneCache() {
        const now = new Date();
        for (const [key, item] of this.cache) {
            if (!item.task.isPending() && dateFn.isAfter(now, item.validUntil)) {
                this.cache.delete(key);
            }
        }
    }

    submit(input: I) {
        this.pruneCache();

        const cacheKey = this.cacheKey(input);
        let task = this.cache.get(cacheKey)?.task;

        if (!task) {
            task = queryPromise(
                new Promise<O>((resolve, reject) =>
                    this.enqueue({
                        input,
                        resolve,
                        reject,
                        attempt: 1,
                        maxAttempts: this.maxAttempts,
                    }),
                ),
            );

            this.cache.set(cacheKey, {
                validUntil: dateFn.addMilliseconds(new Date(), this.maxAge),
                task,
            });
        }

        return task;
    }

    async tick() {
        await this.queueMutex.lock();
        await delay(this.tickDelay);

        const jobs = this.queue.splice(0, this.maxConcurrency);

        if (!jobs.length) {
            return;
        }

        const tasks = this.run(
            jobs.map((job) => ({ input: job.input, attempt: job.attempt, maxAttempts: job.maxAttempts })),
        );

        if (tasks.length != jobs.length) {
            throw new Error(`Expected ${jobs.length} tasks, got ${tasks.length}`);
        }

        await Promise.all(
            A.zipWith(tasks, jobs, (task, job) =>
                task.then(job.resolve, (e) => {
                    if (job.attempt >= job.maxAttempts) {
                        job.reject(e);
                    } else {
                        setTimeout(() => this.enqueue({ ...job, attempt: job.attempt + 1 }), this.backoff(job.attempt));
                    }
                }),
            ),
        );
    }

    stop() {
        this.stopped = true;
    }

    async loop() {
        this.stopped = false;

        while (!this.stopped) {
            await this.tick();
        }
    }

    // override

    cacheKey(input: I) {
        return JSON.stringify(input);
    }

    runOne(job: JobInfo<I>): Promise<O> {
        throw new Error('Not implemented');
    }

    run(jobs: JobInfo<I>[]): Promise<O>[] {
        return jobs.map(this.runOne);
    }
}

export function createExponentialBackoff(factor = 0.3, limit = Number.POSITIVE_INFINITY): BackoffStrategy {
    return (attempt) => Math.min(limit, factor * 2 ** (attempt - 1) * 1000);
}
