import { Component, ComponentProps, For, Match, Switch } from 'solid-js';

import { ImageEmoji } from '@/iro/objects/Emoji';
import { isMatchingN } from '@/lib/ts-pattern/util';
import { EmojiToken, findEmojis } from '@/services/emojis';

import { Emoji } from './Emoji';

export const Emojified: Component<{
    text: string;
    emojis: Record<string, string>;
    emojiSize?: ComponentProps<typeof ImageEmoji>['size'];
    zoomable?: boolean;
}> = (props) => {
    const emojis = () => findEmojis(props.text, props.emojis);

    return (
        <For each={emojis()}>
            {(el) => (
                <Switch>
                    <Match when={isMatchingN(EmojiToken.Emoji.select(), el)} keyed>
                        {(el) => (
                            <Emoji
                                emoji={el.value}
                                customEmojis={props.emojis}
                                size={props.emojiSize}
                                zoomable={props.zoomable}
                            />
                        )}
                    </Match>
                    <Match when={isMatchingN(EmojiToken.Text.select(), el)} keyed>
                        {(el) => el.value}
                    </Match>
                </Switch>
            )}
        </For>
    );
};
