import * as io from 'io-ts';
import { DateFromISOString } from 'io-ts-types/DateFromISOString';

interface DriveFolderA {
    id: string;
    createdAt: Date;
    name: string;
    foldersCount?: number;
    filesCount?: number;
    parentId: string | null;
    parent?: DriveFolderA;
}

interface DriveFolderO extends Omit<DriveFolderA, 'createdAt' | 'parent'> {
    createdAt: string;
    parent?: DriveFolderO;
}

export const DriveFolderC = io.recursion<DriveFolderA, DriveFolderO>('DriveFolderC', (self) =>
    io.intersection([
        io.type({
            id: io.string,
            createdAt: DateFromISOString,
            name: io.string,
            parentId: io.union([io.string, io.null]),
        }),
        io.partial({
            foldersCount: io.number,
            filesCount: io.number,
            parent: self,
        }),
    ]),
);

export type DriveFolder = io.TypeOf<typeof DriveFolderC>;
