import { Exome } from 'exome';
import * as io from 'io-ts';

import { UserC } from '@/entities/user';

import { Session } from './session';

export class User extends Exome {
    id: string;
    username: string;
    host?: string;
    name?: string;
    avatarUrl?: string;
    emojis: Record<string, string> = {};

    deleted = false;

    constructor(
        readonly session: Session,
        data: Pick<io.TypeOf<typeof UserC>, 'id' | 'username'> & Partial<io.TypeOf<typeof UserC>>,
    ) {
        super();

        this.id = data.id;
        this.username = data.username;
        this.update(data);
    }

    get fullUsername() {
        if (!this.host) {
            return `@${this.username}`;
        } else {
            return `@${this.username}@${this.host}`;
        }
    }

    get displayname() {
        return this.name || this.username;
    }

    update(data: Partial<io.TypeOf<typeof UserC>>) {
        if (data.id !== undefined) {
            this.id = data.id;
        }
        if (data.username !== undefined) {
            this.username = data.username;
        }
        if (data.name !== undefined) {
            this.name = data.name ?? undefined;
        }
        if (data.host !== undefined) {
            this.host = data.host ?? undefined;
        }
        if (data.avatarUrl !== undefined) {
            this.avatarUrl = data.avatarUrl ?? undefined;
        }
        if (data.emojis !== undefined) {
            this.emojis = data.emojis.reduce((r, emoji) => ({ ...r, [`:${emoji.name}:`]: emoji.url }), {});
        }
    }

    markDeleted() {
        this.deleted = true;
    }

    fetch = async () => {
        let query;

        if (this.id) {
            query = { userId: this.id };
        } else if (this.username) {
            query = { username: this.username, host: this.host };
        } else {
            return;
        }

        const user = await this.session.userApi.show(query);
        this.update(user);
    };
}
