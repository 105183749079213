import { Component, createEffect, For, onMount } from 'solid-js';

import { useResizeHandle } from '@/iro/hooks/resizeHandle';
import { useScrollTrigger } from '@/iro/hooks/scrollTriggers';
import { Icon } from '@/iro/objects/Icon';
import { OverflowButton } from '@/iro/objects/OverflowButton';
import { ScrollTopButton } from '@/iro/objects/ScrollTopButton';
import { useStore } from '@/lib/exome/solid';
import { Session } from '@/store/session';
import icons from '#/icons.svg';

import { Header } from '../layouts/Header';
import { Notification } from './Notification';

export const NOTIFICATIONS_WINDOW = 30;

export const Sidebar: Component<{ session: Session }> = (props) => {
    const session = useStore(() => props.session);
    const notifications = useStore(() => session().notifications);

    let contentEl!: HTMLDivElement;
    let resizeEl!: HTMLButtonElement;
    let sidebarEl!: HTMLDivElement;

    const [mountBottomTrigger, atBottom] = useScrollTrigger('bottom');
    const mountHandle = useResizeHandle(true);

    createEffect(() => {
        if (atBottom()) {
            props.session.notifications.fetchOlder(NOTIFICATIONS_WINDOW);
        }
    });

    onMount(() => {
        mountBottomTrigger(contentEl);
        mountHandle(resizeEl, sidebarEl);
    });

    return (
        <>
            <button class="o-resize-handle" ref={resizeEl} />

            <aside class="l-sidebar l-sidebar--widgets u-themed t-hi" style={{ width: '350px' }} ref={sidebarEl}>
                <Header right>
                    <div class="l-media__block l-media__block--main">
                        <OverflowButton before={<strong>Notifications</strong>}>
                            <Icon source={icons} id="chevron-down" />
                        </OverflowButton>
                    </div>
                </Header>

                <div class="l-notifications" ref={contentEl}>
                    <For each={notifications().list}>
                        {(notification) => (
                            <Notification notification={notification} class="l-notifications__notification" />
                        )}
                    </For>
                </div>

                <footer class="l-sticky-footer">
                    <ScrollTopButton iconSource={icons} watch={contentEl} class="u-ml-auto" />
                </footer>
            </aside>
        </>
    );
};
