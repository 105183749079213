import * as io from 'io-ts';
import { DateFromISOString } from 'io-ts-types/DateFromISOString';

import { DriveFile, DriveFileC } from './driveFile';
import { Emoji, EmojiC } from './emoji';
import { Poll, PollC } from './poll';
import { User, UserC } from './user';

export enum NoteVisibility {
    Public = 'public',
    Home = 'home',
    Followers = 'followers',
    Specified = 'specified',
}

export const NoteVisibilityC = io.union([
    io.literal(NoteVisibility.Public),
    io.literal(NoteVisibility.Home),
    io.literal(NoteVisibility.Followers),
    io.literal(NoteVisibility.Specified),
]);

interface NoteA {
    id: string;
    createdAt: Date;
    text: string | null;
    cw?: string | null;
    userId: string;
    user: User;
    replyId?: string | null;
    renoteId?: string | null;
    reply?: NoteA;
    renote?: NoteA;
    visibility: NoteVisibility;
    mentions?: string[];
    visibleUserIds?: string[];
    fileIds?: string[];
    files?: DriveFile[];
    tags?: string[];
    poll?: Poll | null;
    channelId?: string | null;
    channel?: Record<string, unknown> | null;
    localOnly?: boolean;
    emojis: Emoji[];
    reactions: Record<string, number>;
    renoteCount: number;
    repliesCount: number;
    uri?: string;
    url?: string;
    myReaction?: string | null;
}

interface NoteO extends Omit<NoteA, 'createdAt' | 'reply' | 'renote' | 'files' | 'poll'> {
    createdAt: string;
    reply?: NoteO;
    renote?: NoteO;
    files?: io.OutputOf<typeof DriveFileC>[];
    poll?: io.OutputOf<typeof PollC> | null;
}

export const NoteC = io.recursion<NoteA, NoteO>('NoteC', (self) =>
    io.intersection([
        io.type({
            id: io.string,
            createdAt: DateFromISOString,
            text: io.union([io.string, io.null]),
            userId: io.string,
            user: UserC,
            visibility: NoteVisibilityC,
            emojis: io.array(EmojiC),
            reactions: io.record(io.string, io.number),
            renoteCount: io.number,
            repliesCount: io.number,
        }),
        io.partial({
            cw: io.union([io.string, io.null]),
            replyId: io.union([io.string, io.null]),
            renoteId: io.union([io.string, io.null]),
            reply: self,
            renote: self,
            mentions: io.array(io.string),
            visibleUserIds: io.array(io.string),
            fileIds: io.array(io.string),
            files: io.array(DriveFileC),
            tags: io.array(io.string),
            poll: io.union([PollC, io.null]),
            localOnly: io.boolean,
            uri: io.string,
            url: io.string,
            myReaction: io.union([io.string, io.null]),
        }),
    ]),
);

export type Note = io.TypeOf<typeof NoteC>;
