import { Exome } from 'exome';

import { NoteUserNotification as NoteUserNotificationE, NotificationType } from '@/entities/notification';

import { Note } from '../note';
import { Session } from '../session';
import { User } from '../user';

export class NoteUserNotification extends Exome {
    id: string;
    createdAt: Date;
    isRead: boolean;
    type: NotificationType.Reply | NotificationType.Mention | NotificationType.Quote | NotificationType.Renote;
    user: User;
    note: Note;

    constructor(readonly session: Session, data: NoteUserNotificationE) {
        super();

        this.id = data.id;
        this.createdAt = data.createdAt;
        this.isRead = data.isRead;
        this.type = data.type;
        this.user = this.session.users.upsert(data.user);
        this.note = this.session.notes.upsert(data.note);
        this.update(data);
    }

    update(data: Partial<NoteUserNotificationE>) {
        if (data.id !== undefined) {
            this.id = data.id;
        }
        if (data.createdAt !== undefined) {
            this.createdAt = data.createdAt;
        }
        if (data.isRead !== undefined) {
            this.isRead = data.isRead;
        }
        if (data.type !== undefined) {
            this.type = data.type;
        }
        if (data.user !== undefined) {
            this.user = this.session.users.upsert(data.user);
        }
        if (data.note !== undefined) {
            this.note = this.session.notes.upsert(data.note);

            if (this.type === NotificationType.Renote && this.note.renote) {
                this.note = this.note.renote;
            }
        }
    }
}
