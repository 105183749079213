import { JSX, Match, mergeProps, ParentComponent, Show, Switch } from 'solid-js';

import { useClickOutside } from '../hooks/clickOutside';
import { ActionButton } from './ActionButton';
import { Backdrop } from './Backdrop';
import { Button } from './Button';
import { H1 } from './Heading';
import { Icon } from './Icon';

export type CloseFn = (trigger?: string) => void;

export const Dialog: ParentComponent<{
    title?: JSX.Element;
    sidebarTitle?: JSX.Element;
    size?: 'lg' | 'md' | 'sm';
    flat?: boolean;
    onClose?: CloseFn;
    class?: string;
    bodyClass?: string;
    cancelLabel?: string;
    scrollable?: boolean;
    noFooter?: boolean;
    noCancel?: boolean;
    okLabel?: string;
    okVariant?: 'accent' | 'primary';
    header?: JSX.Element;
    sidebarHeader?: JSX.Element;
    sidebar?: JSX.Element;
    footer?: JSX.Element | ((close: CloseFn) => JSX.Element);
}> = (_props) => {
    const props = mergeProps(
        {
            size: 'md',
            onClose: () => void 0,
            okVariant: 'accent',
            cancelLabel: 'Cancel',
            okLabel: 'OK',
            bodyClass: '',
            class: '',
        } as const,
        _props,
    );

    const close = (trigger?: string) => props.onClose(trigger);

    const [mountClickOutside] = useClickOutside(close);

    const footer = (close: CloseFn) => (typeof props.footer === 'function' ? props.footer(close) : props.footer);

    return (
        <Backdrop class="u-p-400">
            <aside
                ref={mountClickOutside}
                class={`o-dialog o-dialog--${props.size} ${props.flat ? 'o-dialog--flat' : ''} ${
                    props.scrollable ? 'o-dialog--scrollable' : ''
                } ${props.class}`}
            >
                <Show when={props.sidebarTitle || props.sidebarHeader}>
                    <header
                        class={`o-dialog__header l-media o-dialog__header--sidebar ${
                            !props.flat ? 'u-themed t-down' : ''
                        }`}
                    >
                        <Switch>
                            <Match when={props.sidebarHeader}>{props.sidebarHeader}</Match>
                            <Match when={props.sidebarTitle}>
                                <H1 level="xl" class="o-dialog__title l-media__block l-media__block--main">
                                    {props.sidebarTitle}
                                </H1>
                            </Match>
                        </Switch>
                    </header>
                </Show>

                <header class={`o-dialog__header l-media ${!props.flat ? 'u-themed t-down' : ''}`}>
                    <Switch>
                        <Match when={props.header}>{props.header}</Match>
                        <Match when={props.title}>
                            <H1 level="xl" class="o-dialog__title l-media__block l-media__block--main">
                                {props.title}
                            </H1>
                        </Match>
                    </Switch>

                    <ActionButton
                        type="button"
                        quiet
                        round
                        class="o-dialog__close-btn l-media__block"
                        onClick={[close, 'cancel']}
                    >
                        <Icon id="x" block />
                    </ActionButton>
                </header>

                <Show when={props.sidebar}>
                    <nav class="o-dialog__sidebar">{props.sidebar}</nav>
                </Show>

                <div class={`o-dialog__body ${props.bodyClass}`}>{props.children}</div>

                <Show when={!props.noFooter}>
                    <footer class="o-dialog__footer l-button-group">
                        <Show
                            when={props.footer}
                            keyed
                            fallback={
                                <>
                                    <Show when={!props.noCancel}>
                                        <Button type="button" outline onClick={[close, 'cancel']}>
                                            {props.cancelLabel}
                                        </Button>{' '}
                                    </Show>
                                    <Button
                                        type="submit"
                                        outline={props.okVariant !== 'accent'}
                                        variant={props.okVariant}
                                        onClick={[close, 'ok']}
                                    >
                                        {props.okLabel}
                                    </Button>
                                </>
                            }
                        >
                            {footer(close)}
                        </Show>
                    </footer>
                </Show>
            </aside>
        </Backdrop>
    );
};
