import { Component, JSX, mergeProps, splitProps } from 'solid-js';

export const Header: Component<{ right?: boolean; theme?: string } & JSX.HTMLAttributes<HTMLElement>> = (_props) => {
    const propsWithDefaults = mergeProps({ right: false, class: '' }, _props);
    const [props, propsRest] = splitProps(propsWithDefaults, ['right', 'theme', 'class']);

    return (
        <header
            class={`l-header ${props.right ? 'l-header--right' : ''} l-media ${
                props.theme ? `u-themed t-${props.theme}` : ''
            } ${props.class}`}
            {...propsRest}
        />
    );
};
