import * as dateFn from 'date-fns';
import { Component, createSignal, onCleanup } from 'solid-js';

export const RelativeTime: Component<{ date: Date; class?: string; locale?: string | string[] }> = (props) => {
    const [rerender, setRerender] = createSignal(true);
    const long = () =>
        props.date.toLocaleString(props.locale, {
            minute: '2-digit',
            hour: '2-digit',
            month: 'short',
            day: 'numeric',
            year: 'numeric',
        });

    const date = () => {
        rerender();

        const now = new Date();
        const diff = Math.abs(dateFn.differenceInMilliseconds(now, props.date));

        if (diff < 1000 * 60 * 60 * 24 * 7 * 4) {
            return dateFn.intlFormatDistance(props.date, now, {
                locale: props.locale,
                numeric: 'always',
                style: 'narrow',
            });
        } else if (diff < 1000 * 60 * 60 * 24 * 365) {
            return props.date.toLocaleDateString(props.locale, {
                month: 'short',
                day: 'numeric',
            });
        } else {
            return props.date.toLocaleDateString(props.locale, {
                month: 'short',
                day: 'numeric',
                year: 'numeric',
            });
        }
    };

    let timer: NodeJS.Timeout | undefined;

    const setTimer = () => {
        setRerender((v) => !v);

        let time;
        const diff = Math.abs(dateFn.differenceInMilliseconds(new Date(), props.date));

        if (diff < 1000 * 60) {
            time = 1000;
        } else if (diff < 1000 * 60 * 60) {
            time = 1000 * 60;
        } else if (diff < 1000 * 60 * 60 * 24) {
            time = 1000 * 60 * 60;
        } else {
            time = 1000 * 60 * 60 * 24;
        }

        timer = setTimeout(setTimer, time);
    };

    setTimer();

    onCleanup(() => clearTimeout(timer));

    return (
        <time class={props.class} dateTime={props.date.toISOString()} title={long()}>
            {date()}
        </time>
    );
};
