import * as io from 'io-ts';
import { DateFromISOString } from 'io-ts-types/DateFromISOString';

import { DriveFolderC } from './driveFolder';
import { UserC } from './user';

export const DriveFileC = io.intersection([
    io.type({
        id: io.string,
        createdAt: DateFromISOString,
        name: io.string,
        type: io.string,
        md5: io.string,
        size: io.number,
        isSensitive: io.boolean,
        blurhash: io.union([io.string, io.null]),
        properties: io.partial({
            width: io.number,
            height: io.number,
            orientation: io.number,
            avgColor: io.string,
        }),
        url: io.union([io.string, io.null]),
        thumbnailUrl: io.union([io.string, io.null]),
        comment: io.union([io.string, io.null]),
        folderId: io.union([io.string, io.null]),
        userId: io.union([io.string, io.null]),
    }),
    io.partial({
        folder: DriveFolderC,
        user: UserC,
    }),
]);

export type DriveFile = io.TypeOf<typeof DriveFileC>;
