import { Component, JSX, mergeProps, splitProps } from 'solid-js';

export const ImageEmoji: Component<
    {
        name?: string;
        size?: '100' | '125' | '150' | '200';
        zoomable?: boolean;
        icon?: boolean;
        noTitle?: boolean;
    } & JSX.ImgHTMLAttributes<HTMLImageElement>
> = (_props) => {
    const propsWithDefaults = mergeProps({ size: '100', class: '' }, _props);
    const [props, propsRest] = splitProps(propsWithDefaults, ['name', 'class', 'size', 'zoomable', 'icon', 'noTitle']);

    return (
        <img
            class={`o-emoji o-emoji--${props.size} ${props.zoomable ? 'o-emoji--zoomable' : ''} ${
                props.icon ? 'o-emoji--icon' : ''
            } ${props.class}`}
            alt={props.name}
            title={props.noTitle ? undefined : props.name}
            {...propsRest}
        />
    );
};

export const SvgEmoji: Component<
    {
        source: string;
        id: string;
        size?: '100' | '125' | '150' | '200';
        zoomable?: boolean;
        icon?: boolean;
    } & JSX.SvgSVGAttributes<SVGSVGElement>
> = (_props) => {
    const propsWithDefaults = mergeProps({ size: '100', class: '' }, _props);
    const [props, propsRest] = splitProps(propsWithDefaults, ['source', 'id', 'class', 'size', 'zoomable', 'icon']);

    return (
        <svg
            class={`o-emoji o-emoji--${props.size} ${props.zoomable ? 'o-emoji--zoomable' : ''} ${
                props.icon ? 'o-emoji--icon' : ''
            } ${props.class}`}
            aria-hidden="true"
            {...propsRest}
        >
            <use href={`${props.source}#${props.id}`} />
        </svg>
    );
};
