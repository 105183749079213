import { useIntl } from '@cookbook/solid-intl';
import { getExomeId } from 'exome';
import { Component, createEffect, For, onMount, useContext } from 'solid-js';

import { useScrollTrigger } from '@/iro/hooks/scrollTriggers';
import { useTimeline } from '@/iro/hooks/timeline';
import { Bubble } from '@/iro/objects/Bubble';
import { Button } from '@/iro/objects/Button';
import { Icon } from '@/iro/objects/Icon';
import { OnDemandContext } from '@/iro/objects/OnDemand';
import { OverflowButton } from '@/iro/objects/OverflowButton';
import { ScrollTopButton } from '@/iro/objects/ScrollTopButton';
import { useStore } from '@/lib/exome/solid';
import { getScrollContainer } from '@/lib/scroll';
import { Session } from '@/store/session';
import { Timeline } from '@/store/timeline';
import icons from '#/icons.svg';

import { TimelineNote } from '../components/Note';
import { Header } from '../layouts/Header';
import { ComposeModal } from '../modals/ComposeModal';

export const TIMELINE_WINDOW = 30;

export const TimelineContent: Component<{ timeline: Timeline }> = (props) => {
    const timeline = useStore(() => props.timeline);
    const intl = useIntl();

    let el!: HTMLDivElement;

    const [mountBottomTrigger, atBottom] = useScrollTrigger('bottom');
    const [notes, unseenNotes, resetUnseensCount] = useTimeline(
        () => getExomeId(props.timeline),
        () => timeline().notes,
    );

    const loadLatestNotes = () => {
        resetUnseensCount();
        (getScrollContainer(el) ?? window).scrollTo({ top: 0, behavior: 'smooth' });
    };

    createEffect(() => {
        if (atBottom()) {
            props.timeline.fetchOlder(TIMELINE_WINDOW);
        }
    });

    onMount(() => {
        mountBottomTrigger(el);
    });

    return (
        <div class="l-main">
            <header class="l-sticky-header">
                <Button
                    variant="primary"
                    onClick={loadLatestNotes}
                    class={`o-overlay-button ${unseenNotes().length ? 'is-visible' : ''} u-ml-auto u-mr-auto`}
                >
                    {intl.formatMessage(
                        {
                            id: 'view.home.new_notes',
                            defaultMessage: `{count, plural,
                                    one   {# new note}
                                    other {# new notes}
                                }`,
                        },
                        {
                            count: unseenNotes().length,
                        },
                    )}
                </Button>
            </header>

            <div class="l-main__content l-main__content--narrow l-timeline" ref={el}>
                <div class="l-timeline">
                    <For each={notes()}>
                        {(note) => (
                            <Bubble class="t-up">
                                <TimelineNote note={note} />
                            </Bubble>
                        )}
                    </For>
                </div>
            </div>

            <footer class="l-sticky-footer">
                <ScrollTopButton iconSource={icons} watch={el} class="u-ml-auto" />
            </footer>
        </div>
    );
};

export const TimelineView: Component<{ session: Session }> = (props) => {
    const onDemand = useContext(OnDemandContext);
    const intl = useIntl();

    const composeNote = () => {
        onDemand((close) => <ComposeModal onClose={close} />);
    };

    return (
        <>
            <Header>
                <div class="l-media__block l-media__block--main">
                    <OverflowButton
                        before={
                            <strong>
                                {intl.formatMessage({ id: 'view.home.header.home', defaultMessage: 'Home' })}
                            </strong>
                        }
                    >
                        <Icon source={icons} id="chevron-down" />
                    </OverflowButton>
                </div>
                <div class="l-media__block">
                    <Button outline onClick={composeNote}>
                        {intl.formatMessage({ id: 'view.home.header.new_note', defaultMessage: 'Note' })}{' '}
                        <Icon source={icons} id="edit" class="u-ml-50" />
                    </Button>
                </div>
            </Header>

            <TimelineContent timeline={props.session.timeline} />
        </>
    );
};
