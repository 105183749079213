import { Accessor, createEffect, createSignal, on } from 'solid-js';

const lastSeenMap: Record<string, any> = {};

export const useTimeline = <T>(id: Accessor<string>, list: Accessor<T[]>) => {
    const [lastSeen, setLastSeen] = createSignal<T | undefined>();

    const unseenList = () => {
        const ls = lastSeen();
        let i = ls ? list().indexOf(ls) : -1;

        if (i === -1) {
            i = 0;
        }

        return list().slice(0, i);
    };

    const visibleList = () => {
        const ls = lastSeen();
        let i = ls ? list().indexOf(ls) : -1;

        if (i === -1) {
            i = 0;
        }

        return list().slice(i);
    };

    const resetUnseen = () => setLastSeen(() => list()[0]);

    createEffect(() => setLastSeen(lastSeenMap[id()]));
    createEffect(
        on(list, (list) => {
            if (!lastSeen()) {
                setLastSeen(() => list[0]);
            }
        }),
    );
    createEffect(on(lastSeen, (lastSeen) => (lastSeenMap[id()] = lastSeen)));

    return [visibleList, unseenList, resetUnseen] as const;
};
