import { createSignal, onCleanup, requestCallback } from 'solid-js';

import { getScrollContainer } from '@/lib/scroll';

export const useScrollTop = () => {
    let io: IntersectionObserver | undefined;
    let scrollEl: HTMLElement | Window | undefined;

    const [show, setShow] = createSignal(false);

    const scrollTop = () => scrollEl?.scrollTo({ top: 0, behavior: 'smooth' });

    const cb: IntersectionObserverCallback = (entries) => {
        setShow(!!entries[0]?.isIntersecting);
    };

    const mount = (el: HTMLElement) => {
        io?.disconnect();

        const root = getScrollContainer(el);

        scrollEl = root ?? window;
        io = new IntersectionObserver(cb, {
            root,
            rootMargin: '200% 0px -200% 0px',
        });

        io.observe(el);

        requestCallback(() => io && cb(io.takeRecords(), io));
    };

    onCleanup(() => {
        io?.disconnect();
    });

    return [mount, show, scrollTop] as const;
};
