export type QueryPromise<T> = Promise<T> & { isFulfilled(): boolean; isPending(): boolean; isRejected(): boolean };

export function queryPromise<T>(promise: Promise<T> | QueryPromise<T>): QueryPromise<T> {
    if ('isFulfilled' in promise) {
        return promise;
    }

    let isPending = true;
    let isRejected = false;
    let isFulfilled = false;

    const p = promise.then(
        (v) => {
            isFulfilled = true;
            isPending = false;
            return v;
        },
        (e) => {
            isRejected = true;
            isPending = false;
            throw e;
        },
    ) as QueryPromise<T>;

    p.isPending = () => isPending;
    p.isRejected = () => isRejected;
    p.isFulfilled = () => isFulfilled;

    return p;
}
