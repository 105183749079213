import * as E from 'fp-ts/Either';
import * as io from 'io-ts';
import ky from 'ky';

export const StaticConfigC = io.partial({
    server: io.string,
    hashRouting: io.boolean,
});

export type StaticConfig = io.TypeOf<typeof StaticConfigC>;

export const staticDefaults = {
    server: undefined,
    hashRouting: true,
};

export const staticConfig = (async () => {
    const res = await ky
        .get('./config.json')
        .json()
        .catch(() => ({}));
    const dec = StaticConfigC.decode(res);
    const config = E.isRight(dec) ? dec.right : {};

    return { ...staticDefaults, ...config };
})();
