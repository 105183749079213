import { onCleanup } from 'solid-js';

const stack: HTMLElement[] = [];

export const useClickOutside = (
    onClickOutside: () => void,
    { rightClick, excludeRoot }: { rightClick?: boolean; excludeRoot?: boolean } = {},
) => {
    let el: HTMLElement | undefined;
    let mouseDown = false;

    const pop = () => {
        if (el) {
            const i = stack.indexOf(el);
            if (i !== -1) {
                stack.splice(i, 1);
            }
        }
    };

    const handleMouseDown = (e: MouseEvent) => {
        mouseDown = !el || !el.contains(e.target as Node) || (!!excludeRoot && el === e.target);
    };

    const handleClickOutside = (e: Event) => {
        if (stack.length && stack[stack.length - 1] === el) {
            e?.preventDefault();
            e?.stopPropagation();
            onClickOutside();
        }
    };

    const handleClick = (e: MouseEvent) => {
        if (mouseDown) {
            mouseDown = false;
            handleClickOutside(e);
        }
    };

    const handleContextmenu = (e: MouseEvent) => handleClick(e);

    const handleKeyUp = (e: KeyboardEvent) => {
        if (e.code === 'Escape') {
            handleClickOutside(e);
        }
    };

    const mount = (_el: HTMLElement) => {
        pop();
        el = _el;
        stack.push(el);

        document.addEventListener('mousedown', handleMouseDown, { capture: true });
        document.addEventListener('click', handleClick, { capture: true });
        document.addEventListener('keyup', handleKeyUp);

        if (rightClick) {
            document.addEventListener('contextmenu', handleContextmenu, { capture: true });
        }
    };

    const unmount = () => {
        pop();

        document.removeEventListener('mousedown', handleMouseDown, { capture: true });
        document.removeEventListener('click', handleClick, { capture: true });
        document.removeEventListener('keyup', handleKeyUp);
        document.removeEventListener('contextmenu', handleContextmenu, { capture: true });
    };

    onCleanup(unmount);

    return [mount, unmount] as const;
};
