import * as dateFn from 'date-fns';
import { Exome } from 'exome';
import * as io from 'io-ts';

import { NoteC } from '@/entities/note';
import { ChannelNoteMessage } from '@/entities/stream';

import { Note } from './note';
import { Session } from './session';

export class Timeline extends Exome {
    notes: Note[] = [];

    constructor(readonly session: Session) {
        super();

        session.streamApi.subscribeTimeline(this.streamUpdate);
    }

    private streamUpdate = (msg: ChannelNoteMessage) => {
        this.update([msg.body]);
    };

    update(data: io.TypeOf<typeof NoteC>[]) {
        if (!data.length) {
            return;
        }

        const populated = data
            .map(this.session.notes.upsert)
            .sort((a, b) => dateFn.compareDesc(a.createdAt, b.createdAt));

        if (
            !this.notes.length ||
            dateFn.isBefore(populated[0]!.createdAt, this.notes[this.notes.length - 1]!.createdAt)
        ) {
            this.notes.push(...populated);
        } else {
            this.notes.unshift(...populated);
        }
    }

    fetch = async (params: {
        limit?: number;
        sinceId?: string;
        untilId?: string;
        sinceDate?: number;
        untilDate?: number;
        includeMyRenotes?: boolean;
        includeRenotedMyNotes?: boolean;
        includeLocalRenotes?: boolean;
        withFiles?: boolean;
    }) => {
        const notes = await this.session.noteApi.timeline({ limit: 11, ...params });

        if (notes.length) {
            this.update(notes);
        }
    };

    fetchNewer = async (limit: number) => {
        const sinceId = this.notes[0]?.id;
        await this.fetch({ limit, sinceId });
    };

    fetchOlder = async (limit: number) => {
        const untilId = this.notes[this.notes.length - 1]?.id;
        await this.fetch({ limit, untilId });
    };
}
