import * as io from 'io-ts';
import { DateFromISOString } from 'io-ts-types/DateFromISOString';

export const PollC = io.intersection([
    io.type({
        choices: io.array(
            io.type({
                text: io.string,
                votes: io.number,
                isVoted: io.boolean,
            }),
        ),
    }),
    io.partial({
        multiple: io.boolean,
        expiresAt: io.union([DateFromISOString, io.null]),
        expiredAfter: io.union([io.number, io.null]),
    }),
]);

export type Poll = io.TypeOf<typeof PollC>;
