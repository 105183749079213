/* eslint-disable @typescript-eslint/no-non-null-assertion */

import { Exome } from 'exome';
import { subscribe } from 'exome/subscribe';
import { Accessor, createMemo, createSignal, onCleanup } from 'solid-js';

function noop() {
    /**/
}

export function useStore<T extends Exome>(store: () => T): Accessor<Readonly<T>> {
    const [state, setState] = createSignal(store(), { equals: false });
    const unsubscribe = createMemo<() => void>((unsubscribe) => {
        unsubscribe();
        return subscribe(store(), setState);
    }, noop);

    onCleanup(() => unsubscribe()());

    return state;
}
