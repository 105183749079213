import { Component, For, mergeProps, Show } from 'solid-js';

import { ActionNavLink } from '@/iro/objects/ActionButton';
import { Bubble } from '@/iro/objects/Bubble';
import { useStore } from '@/lib/exome/solid';
import * as routes from '@/routes';
import { Note as NoteStore } from '@/store/note';

import { MAX_THREAD_DEPTH } from '../views/Note';
import { TimelineNote } from './Note';

export const RepliesOf: Component<{ note: NoteStore; depth?: number; referenceNoteId?: string }> = (_props) => {
    const props = mergeProps({ depth: MAX_THREAD_DEPTH }, _props);
    const note = useStore(() => props.note);
    const referenceNoteId = () => props.referenceNoteId ?? note().id;

    return (
        <Show when={note().children.length}>
            <Show
                when={props.depth}
                fallback={
                    <ActionNavLink href={routes.note(props.note.session.id, note().id)} quiet>
                        {note().repliesCount} more replies
                    </ActionNavLink>
                }
            >
                <div class="l-replies">
                    <For each={note().children}>
                        {(child) => (
                            <>
                                <Bubble class="t-up">
                                    <TimelineNote note={child} noReplyContext referenceNoteId={referenceNoteId()} />
                                </Bubble>
                                <RepliesOf note={child} depth={props.depth - 1} referenceNoteId={referenceNoteId()} />
                            </>
                        )}
                    </For>
                </div>
            </Show>
        </Show>
    );
};
