import { ParentComponent, Show } from 'solid-js';

import { Icon } from '@/iro/objects/Icon';
import icons from '#/icons.svg';
import symbols from '#/symbols.svg';

export const MessageView: ParentComponent<{ iconSource: string; icon: string }> = (props) => (
    <div class="l-main l-main--centered">
        <div class="l-main__content">
            <Icon source={props.iconSource} id={props.icon} class="u-ml-auto u-mr-auto" large block />
            <Show when={props.children} keyed>
                {(children) => <div class="u-mt-100 u-ta-center">{children}</div>}
            </Show>
        </div>
    </div>
);

export const ErrorView: ParentComponent = (props) => (
    <MessageView iconSource={icons} icon="alert-triangle" {...props} />
);

export const LoadingView: ParentComponent = (props) => <MessageView iconSource={symbols} icon="loading" {...props} />;
