import { Exome } from 'exome';
import ky from 'ky';

import { NoteApi } from '@/services/api/note';
import { NotificationApi } from '@/services/api/notification';
import { StreamApi } from '@/services/api/stream';
import { UserApi } from '@/services/api/user';

import { NoteList } from './noteList';
import { NotificationList } from './notificationList';
import { Timeline } from './timeline';
import { User } from './user';
import { UserList } from './userList';

export enum SessionStatus {
    Loading,
    Ok,
    Err,
}

export class Session extends Exome {
    readonly client;
    readonly server;
    status = SessionStatus.Loading;
    self?: User;
    readonly streamApi;
    readonly userApi;
    readonly noteApi;
    readonly notificationApi;
    readonly users;
    readonly notes;
    readonly timeline;
    readonly notifications;

    constructor(readonly id: string, server: string, readonly token: string) {
        super();

        this.server = new URL(/^https?:\/\//.test(server) ? server : `https://${server}`);

        const clientUrl = new URL('api/', this.server);

        this.client = ky.extend({
            prefixUrl: clientUrl,
            headers: {
                authorization: `Bearer ${token}`,
            },
        });

        const socketUrl = new URL('streaming', this.server);
        socketUrl.protocol = 'wss';
        socketUrl.searchParams.set('i', token);

        this.streamApi = new StreamApi(socketUrl);
        this.userApi = new UserApi(this);
        this.noteApi = new NoteApi(this);
        this.notificationApi = new NotificationApi(this);
        this.users = new UserList(this);
        this.notes = new NoteList(this);
        this.timeline = new Timeline(this);
        this.notifications = new NotificationList(this);

        this.init();
    }

    async init() {
        try {
            const self = await this.userApi.self();
            this.self = this.users.upsert(self);
            this.status = SessionStatus.Ok;
        } catch (e) {
            console.error(e);
            this.status = SessionStatus.Err;
        }
    }
}
