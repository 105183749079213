import { Exome } from 'exome';

export class GlobalConfig extends Exome {
    locale = 'en';

    setLocale(locale: string) {
        this.locale = locale;
    }
}

export const globalConfig = new GlobalConfig();
