import { Component, ComponentProps, splitProps } from 'solid-js';

import { Avatar as GenericAvatar } from '@/iro/objects/Avatar';
import { useStore } from '@/lib/exome/solid';
import * as routes from '@/routes';
import { User } from '@/store/user';

export const Avatar: Component<
    { user: User; noLink?: boolean } & Omit<ComponentProps<typeof GenericAvatar>, 'name' | 'href'>
> = (_props) => {
    const [props, propsRest] = splitProps(_props, ['user', 'noLink']);
    const user = useStore(() => props.user);
    const link = () => (props.noLink ? undefined : routes.user(props.user.session.id, user().fullUsername));

    return <GenericAvatar {...propsRest} name={user().fullUsername} href={link()} image={user().avatarUrl} />;
};
