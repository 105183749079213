import { useIntl } from '@cookbook/solid-intl';
import { Component, createSignal } from 'solid-js';

import { Form, FormItem } from '@/iro/layouts/Form';
import { Button } from '@/iro/objects/Button';
import { Divider } from '@/iro/objects/Divider';
import { H1 } from '@/iro/objects/Heading';
import { TextField } from '@/iro/objects/TextField';
import { useStore } from '@/lib/exome/solid';
import { sessionList as sessionList_ } from '@/store/sessionList';

export const SetupView: Component = () => {
    const sessionList = useStore(() => sessionList_);

    const [server, setServer] = createSignal('');
    const [token, setToken] = createSignal('');

    const actualServer = () => sessionList().server ?? server();

    const intl = useIntl();

    const setServerHandler = (e: InputEvent & { currentTarget: HTMLInputElement }) => setServer(e.currentTarget.value);
    const setTokenHandler = (e: InputEvent & { currentTarget: HTMLInputElement }) => setToken(e.currentTarget.value);
    const submitHandler = (e: Event) => {
        e.preventDefault();
        sessionList().create(actualServer(), token());
    };

    return (
        <div class="l-fullscreen">
            <div class="l-container l-container--narrow-75 u-p-700">
                <H1 level="xl" class="u-mt-0">
                    Misskey Frontend
                </H1>

                <Divider variant="medium" />

                <Form labelPosition="left" onSubmit={submitHandler} class="u-mt-400">
                    <FormItem
                        disabled={!!sessionList().server}
                        label={intl.formatMessage({ id: 'view.setup.server.label', defaultMessage: 'Server' })}
                        hint={intl.formatMessage({
                            id: 'view.setup.server.hint',
                            defaultMessage: 'e.g. https://is-a.wyvern.rip/',
                        })}
                    >
                        <TextField
                            disabled={!!sessionList().server}
                            value={actualServer()}
                            onInput={setServerHandler}
                            required
                        />
                    </FormItem>

                    <FormItem label={intl.formatMessage({ id: 'view.setup.token.label', defaultMessage: 'Token' })}>
                        <TextField value={token()} onInput={setTokenHandler} required />
                    </FormItem>

                    <FormItem>
                        <Button variant="primary">
                            {intl.formatMessage({ id: 'view.setup.connect', defaultMessage: 'Connect' })}
                        </Button>
                    </FormItem>
                </Form>
            </div>
        </div>
    );
};
