import { Component, Match, splitProps, Switch } from 'solid-js';

import { ImageEmoji, SvgEmoji } from '@/iro/objects/Emoji';
import { emojiData } from '@/services/emojis';

export const Emoji: Component<{
    emoji: string;
    customEmojis?: Record<string, string>;
    size?: '100' | '125' | '150' | '200';
    zoomable?: boolean;
    icon?: boolean;
    class?: string;
    noTitle?: boolean;
}> = (_props) => {
    const [props, propsRest] = splitProps(_props, ['emoji', 'customEmojis']);

    return (
        <Switch fallback={<span class={propsRest.class}>{props.emoji}</span>}>
            <Match when={props.customEmojis?.[props.emoji]} keyed>
                {(src) => <ImageEmoji src={src} name={props.emoji} {...propsRest} />}
            </Match>
            <Match when={emojiData(props.emoji)} keyed>
                {(data) => <SvgEmoji source={data.source} id={data.id} {...propsRest} />}
            </Match>
        </Switch>
    );
};
