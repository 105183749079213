import { Component, JSX, mergeProps, Show, splitProps } from 'solid-js';

import { callEventHandler } from '@/lib/solid/eventHandler';

import { useAutosize } from '../hooks/autosize';

export const TextArea: Component<
    {
        top?: JSX.Element;
        before?: JSX.Element;
        after?: JSX.Element;
        bottom?: JSX.Element;
        bgClass?: string;
        invalid?: boolean;
        enterkeyhint?: string;
        minLines?: number;
        maxLines?: number;
    } & Omit<JSX.TextareaHTMLAttributes<HTMLTextAreaElement>, 'children'>
> = (_props) => {
    const propsWithDefaults = mergeProps({ bgClass: '', class: '' }, _props);
    const [props, propsRest] = splitProps(propsWithDefaults, [
        'value',
        'top',
        'bottom',
        'before',
        'after',
        'disabled',
        'bgClass',
        'invalid',
        'style',
        'onInput',
        'class',
        'minLines',
        'maxLines',
    ]);
    const value = () => props.value;
    const [mountAutosize, height] = useAutosize([value], { min: props.minLines, max: props.maxLines }); // eslint-disable-line solid/reactivity

    const mergedStyle = () =>
        typeof props.style === 'string' ? `${props.style}; height: ${height()}` : { ...props.style, height: height() };

    const onInput: JSX.EventHandler<HTMLTextAreaElement, InputEvent> = (e) => {
        callEventHandler(props.onInput, e);
        if (props.value !== undefined && props.value !== e.currentTarget.value) {
            e.currentTarget.value = props.value as any;
        }
    };

    return (
        <div
            class={`o-text-field ${props.disabled ? 'is-disabled' : ''} ${props.invalid ? 'is-invalid' : ''} ${
                props.top || props.bottom || props.before || props.after ? 'o-text-field--extended' : ''
            } ${props.class}`}
        >
            <Show when={props.top}>
                <div class="l-media l-media--flush l-media--gapless t-hi">{props.top}</div>
            </Show>
            <div class="l-media l-media--flush l-media--gapless">
                <Show when={props.before}>
                    <div class="u-d-contents t-hi">{props.before}</div>
                </Show>
                <textarea
                    ref={mountAutosize}
                    value={props.value}
                    class="o-text-field__native l-media__block l-media__block--main"
                    style={mergedStyle()}
                    onInput={onInput}
                    {...propsRest}
                />
                <Show when={props.after}>
                    <div class="u-d-contents t-hi">{props.after}</div>
                </Show>
                <div class={`o-text-field__bg ${props.bgClass}`} />
            </div>
            <Show when={props.bottom}>
                <div class="l-media l-media--flush l-media--gapless t-hi">{props.bottom}</div>
            </Show>
        </div>
    );
};
