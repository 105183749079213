import { useIntl } from '@cookbook/solid-intl';
import { Component, mergeProps } from 'solid-js';

import { Time } from '@/iro/objects/Time';
import { useStore } from '@/lib/exome/solid';
import { Note } from '@/store/note';
import { Avatar } from '@/ui/objects/Avatar';

import { Emojified } from '../objects/Emojified';

export const NoteCard: Component<{
    note: Note;
    class?: string;
    size?: '100' | '75' | '50';
}> = (_props) => {
    const props = mergeProps({ size: '100', class: '' } as const, _props);
    const note = useStore(() => props.note);
    const user = useStore(() => note().user);
    const intl = useIntl();

    return (
        <div class={`l-media l-media--flush ${props.size !== '100' ? 'l-media--75' : ''} ${props.class}`}>
            <Avatar user={props.note.user} class="l-media__block" />
            <div class="l-media__block l-media__block--main">
                <div class="u-elp">
                    <strong class="u-d-block">
                        <Emojified text={user().displayname} emojis={user().emojis} />
                    </strong>
                    <small class="u-d-block">
                        <Time date={note().createdAt} locale={intl.locale} />
                    </small>
                </div>
            </div>
        </div>
    );
};
