import { Exome } from 'exome';
import * as E from 'fp-ts/Either';
import * as F from 'fp-ts/function';
import * as J from 'fp-ts/Json';
import * as M from 'fp-ts/Map';
import * as O from 'fp-ts/Ord';
import * as S from 'fp-ts/string';
import * as io from 'io-ts';

import { staticConfig } from '../staticConfig';
import { Session } from './session';

export const SessionConfigC = io.type({
    id: io.string,
    server: io.string,
    token: io.string,
});

export const SessionIdOrd = O.contramap((session: Session) => session.id)(S.Ord);

export class SessionList extends Exome {
    private sessionsById = new Map<string, Session>();
    loading = true;
    server?: string;

    constructor() {
        super();
        this.load();
    }

    private manualEmit() {
        /* noop */
    }

    async load() {
        const conf = await staticConfig;

        this.server = conf.server;

        const sessions = F.pipe(
            localStorage.getItem('misskey-fe:sessions') ?? '',
            J.parse,
            E.chainW(io.array(SessionConfigC).decode),
        );

        if (E.isRight(sessions)) {
            for (const s of sessions.right) {
                this.create(this.server ?? s.server, s.token, s.id);
            }
        }

        this.loading = false;
    }

    get list() {
        return F.pipe(this.sessionsById, M.values(SessionIdOrd));
    }

    get first() {
        return Array.from(this.sessionsById.values())[0];
    }

    generateId = () => {
        let i;
        for (i = 0; this.sessionsById.has(`${i}`); ++i);
        return `${i}`;
    };

    get = (id: string) => this.sessionsById.get(id);

    create(server: string, token: string, id = this.generateId()) {
        const session = new Session(id, this.server ?? server, token);
        this.sessionsById.set(session.id, session);
        this.save();
        return session;
    }

    delete = (id: string) => {
        const session = this.get(id);

        if (session) {
            this.sessionsById.delete(id);
            this.manualEmit();
            this.save();
        }

        return session;
    };

    save = () => {
        if (this.loading) {
            return;
        }

        const result = F.pipe(
            this.sessionsById,
            M.collect(S.Ord)((_, session) => ({ id: session.id, server: session.server, token: session.token })),
            J.stringify,
            E.chain((conf) =>
                E.tryCatch(
                    () => localStorage.setItem('misskey-fe:sessions', conf),
                    (e) => e,
                ),
            ),
        );

        if (E.isLeft(result)) {
            console.error(result.left);
        }
    };
}

export const sessionList = new SessionList();
