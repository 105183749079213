import { Exome } from 'exome';

import { NoteNotification as NoteNotificationE, NotificationType } from '@/entities/notification';

import { Note } from '../note';
import { Session } from '../session';

export class NoteNotification extends Exome {
    id: string;
    createdAt: Date;
    isRead: boolean;
    type: NotificationType.PollEnded;
    note: Note;

    constructor(readonly session: Session, data: NoteNotificationE) {
        super();

        this.id = data.id;
        this.createdAt = data.createdAt;
        this.isRead = data.isRead;
        this.type = data.type;
        this.note = this.session.notes.upsert(data.note);
        this.update(data);
    }

    update(data: Partial<NoteNotificationE>) {
        if (data.id !== undefined) {
            this.id = data.id;
        }
        if (data.createdAt !== undefined) {
            this.createdAt = data.createdAt;
        }
        if (data.isRead !== undefined) {
            this.isRead = data.isRead;
        }
        if (data.type !== undefined) {
            this.type = data.type;
        }
        if (data.note !== undefined) {
            this.note = this.session.notes.upsert(data.note);
        }
    }
}
