import { Component, createEffect, createSignal, JSX, Match, Show, Switch } from 'solid-js';

import { Button } from '@/iro/objects/Button';
import { Icon } from '@/iro/objects/Icon';
import { LightboxItem } from '@/iro/objects/Lightbox';
import { callEventHandler } from '@/lib/solid/eventHandler';
import icons from '#/icons.svg';

export interface Attachment extends LightboxItem {
    isSensitive: boolean;
}

export const Attachment: Component<{
    attachment: Attachment;
    onClick?: JSX.EventHandlerUnion<HTMLElement, MouseEvent>;
}> = (props) => {
    const [hidden, setHidden] = createSignal(false);

    const onClick = (e: MouseEvent) => callEventHandler(props.onClick, e);

    createEffect(() => setHidden(props.attachment.isSensitive));

    return (
        <div class="c-attachment">
            <Show when={props.attachment.isSensitive}>
                <Button
                    round
                    class="c-attachment__hide-btn t-dark"
                    onClick={[setHidden, (v: boolean) => !v]}
                    title={hidden() ? 'Show attachment' : 'Hide attachment'}
                >
                    <Icon source={icons} id={hidden() ? 'eye' : 'eye-slash'} />
                </Button>

                <Show when={hidden()}>
                    <button class="c-attachment__notice t-dark" onClick={[setHidden, false]}>
                        <strong>
                            <Icon source={icons} id="alert-triangle" /> NSFW
                        </strong>
                        <div>Click to show</div>
                    </button>
                </Show>
            </Show>

            <Switch>
                <Match when={props.attachment.type.startsWith('image/')}>
                    <a class="c-attachment__object c-attachment--image" href={props.attachment.src} onClick={onClick}>
                        <img class="c-attachment__img" src={props.attachment.thumbnail ?? undefined} />
                    </a>
                </Match>

                <Match when={props.attachment.type.startsWith('video/')}>
                    <video controls class="c-attachment__object c-attachment--video">
                        <source src={props.attachment.src} type={props.attachment.type} />
                    </video>
                </Match>

                <Match when={props.attachment.type.startsWith('audio/')}>
                    <audio controls class="c-attachment__object c-attachment--audio">
                        <source src={props.attachment.src} type={props.attachment.type} />
                    </audio>
                </Match>
            </Switch>
        </div>
    );
};
