import * as io from 'io-ts';

import { EmojiC } from './emoji';
import { NoteC } from './note';
import { NotificationC } from './notification';

export interface ChannelConnect {
    type: 'connect';
    body: {
        channel: string;
        id: string;
        params?: Record<string, unknown>;
    };
}

export interface ChannelSend {
    type: 'channel';
    body: {
        id: string;
        type: string;
        body?: Record<string, unknown>;
    };
}

export interface ChannelDisconnect {
    type: 'disconnect';
    body: {
        id: string;
    };
}

export const ChannelNoteMessageC = io.type({
    type: io.literal('note'),
    body: NoteC,
});

export type ChannelNoteMessage = io.TypeOf<typeof ChannelNoteMessageC>;

export const ChannelNotificationMessageC = io.type({
    type: io.literal('notification'),
    body: NotificationC,
});

export type ChannelNotificationMessage = io.TypeOf<typeof ChannelNotificationMessageC>;

export const ChannelMessageC = io.type({
    type: io.literal('channel'),
    body: io.intersection([
        io.type({
            id: io.string,
        }),
        io.union([ChannelNoteMessageC, ChannelNotificationMessageC]),
    ]),
});

export type ChannelMessage = io.TypeOf<typeof ChannelMessageC>;

export interface NoteCapture {
    type: 'subNote';
    body: {
        id: string;
    };
}

export interface NoteUncapture {
    type: 'unsubNote';
    body: {
        id: string;
    };
}

export const NoteReactUpdateC = io.type({
    type: io.literal('reacted'),
    body: io.type({
        reaction: io.string,
        userId: io.string,
        emoji: EmojiC,
    }),
});

export type NoteReactUpdate = io.TypeOf<typeof NoteReactUpdateC>;

export const NoteUpdateC = io.type({
    type: io.literal('noteUpdated'),
    body: io.intersection([
        io.type({
            id: io.string,
        }),
        NoteReactUpdateC,
    ]),
});

export type NoteUpdate = io.TypeOf<typeof NoteUpdateC>;

export type Message = ChannelMessage | NoteUpdate;
