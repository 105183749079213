import emojiRegex_ from 'emoji-regex';

import { impl, Variant } from '@/lib/union-types';
import emojis from '#/emojis.json';
import emojis0 from '#/emojis_0.svg';
import emojis1 from '#/emojis_1.svg';
import emojis2 from '#/emojis_2.svg';
import emojis3 from '#/emojis_3.svg';
import emojis4 from '#/emojis_4.svg';
import emojis5 from '#/emojis_5.svg';
import emojis6 from '#/emojis_6.svg';
import emojis7 from '#/emojis_7.svg';
import emojis8 from '#/emojis_8.svg';
import emojis9 from '#/emojis_9.svg';
import emojis10 from '#/emojis_10.svg';
import emojis11 from '#/emojis_11.svg';
import emojis12 from '#/emojis_12.svg';
import emojis13 from '#/emojis_13.svg';
import emojis14 from '#/emojis_14.svg';
import emojis15 from '#/emojis_15.svg';
import emojis16 from '#/emojis_16.svg';
import emojis17 from '#/emojis_17.svg';
import emojis18 from '#/emojis_18.svg';
import emojis19 from '#/emojis_19.svg';

export type EmojiToken = Variant<'Emoji', string> | Variant<'Text', string>;

export const EmojiToken = impl<EmojiToken>();

export function emojiData(emoji: string) {
    const data = emojis[emoji as keyof typeof emojis];

    if (data === undefined) {
        return;
    }

    const source = [
        emojis0,
        emojis1,
        emojis2,
        emojis3,
        emojis4,
        emojis5,
        emojis6,
        emojis7,
        emojis8,
        emojis9,
        emojis10,
        emojis11,
        emojis12,
        emojis13,
        emojis14,
        emojis15,
        emojis16,
        emojis17,
        emojis18,
        emojis19,
    ][data.index];

    if (source === undefined) {
        return;
    }

    return { source, id: data.id };
}

export function customEmojiRegex(emojis: Record<string, string>, flags?: string) {
    if (!Object.keys(emojis).length) {
        return /$^/;
    }
    return new RegExp(Object.keys(emojis).join('|'), flags);
}

export function emojiRegex(emojis: Record<string, string>, flags?: string) {
    return new RegExp(`${emojiRegex_().source}|${customEmojiRegex(emojis).source}`, flags);
}

export function findEmojis(text: string, customEmojis: Record<string, string>): EmojiToken[] {
    const items: EmojiToken[] = [];
    const regex = emojiRegex(customEmojis, 'g');

    for (;;) {
        const start = regex.lastIndex;
        const match = regex.exec(text);

        if (!match) {
            items.push(EmojiToken.Text(text.slice(start)));
            break;
        }

        items.push(EmojiToken.Text(text.slice(start, match.index)));
        items.push(EmojiToken.Emoji(match[0]));
    }

    return items;
}
