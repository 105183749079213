import { useIntl } from '@cookbook/solid-intl';
import { JSX, mergeProps, ParentComponent, Show } from 'solid-js';

import { noteVisibilityIcon, noteVisibilityMsg } from '@/i18n/noteVisibility';
import { useOnDemand } from '@/iro/hooks/onDemand';
import { ActionButton, ActionNavLink } from '@/iro/objects/ActionButton';
import { Icon } from '@/iro/objects/Icon';
import { RelativeTime } from '@/iro/objects/RelativeTime';
import { useStore } from '@/lib/exome/solid';
import * as routes from '@/routes';
import { Note as NoteStore } from '@/store/note';
import { NoteMenu } from '@/ui/menus/NoteMenu';
import icons from '#/icons.svg';

import { UserCard } from '../../objects/UserCard';

export const NoteHeader: ParentComponent<{
    note: NoteStore;
    icon?: string;
    size?: '75' | '100' | '150';
    noMenu?: boolean;
    noLink?: boolean;
    noDate?: boolean;
}> = (_props) => {
    const props = mergeProps({ size: '100' } as const, _props);
    const note = useStore(() => props.note);
    const intl = useIntl();

    const [createMenu, menuVisible] = useOnDemand();

    const handleMenu: JSX.EventHandler<HTMLButtonElement, MouseEvent> = (e) => {
        e.preventDefault();
        const anchor = e.currentTarget;
        createMenu((close) => <NoteMenu note={props.note} anchor={anchor} onClose={close} />);
    };

    return (
        <header class="c-note__header l-media l-media--flush">
            <Show when={props.icon} keyed>
                {(icon) => <Icon source={icons} id={icon} class="l-media__block" />}
            </Show>

            <UserCard user={note().user} avatar={props.size}>
                <Show when={!props.noDate}>
                    <span class="u-ml-50 u-mr-50"> · </span>
                    <span title={intl.formatMessage(noteVisibilityMsg[note().visibility].label)}>
                        <Icon source={icons} id={noteVisibilityIcon[note().visibility]} />
                    </span>{' '}
                    <RelativeTime date={note().createdAt} locale={intl.locale} class="u-ml-50" />
                </Show>
            </UserCard>

            <div class="c-note__menu-actions l-media__block">
                {props.children}
                <Show when={!props.noMenu}>
                    <ActionButton round quiet selected={menuVisible()} onClick={handleMenu}>
                        <Icon source={icons} id="ellipsis-h" />
                    </ActionButton>
                </Show>
                <Show when={!props.noLink}>
                    <ActionNavLink round quiet href={routes.note(props.note.session.id, note().id)}>
                        <Icon source={icons} id="arrow-up-right" />
                    </ActionNavLink>
                </Show>
            </div>
        </header>
    );
};
