import { route } from './lib/routing';

export const setup = route('/setup');
export const home = route('/:session/home');
export const user = route('/:session/user/:userId');
export const note = route('/:session/note/:noteId');
export const drive = route('/:session/drive');
export const hashtags = route('/:session/tags');
export const hashtag = route('/:session/tags/:tag');
export const settings = route('/:session/settings');
