import { NavLinkProps } from '@solidjs/router';
import { Component, JSX, mergeProps, splitProps } from 'solid-js';

import { ActionButton, ActionNavLink } from './ActionButton';
import { Icon } from './Icon';

export const IconNavItem: Component<
    { selected?: boolean; icon: [string, string] } & JSX.ButtonHTMLAttributes<HTMLButtonElement>
> = (_props) => {
    const propsWithDefaults = mergeProps({ class: '' }, _props);
    const [props, propsRest] = splitProps(propsWithDefaults, ['icon', 'class', 'children']);

    return (
        <ActionButton class={`o-icon-nav__item ${props.class}`} quiet round {...propsRest}>
            <Icon source={props.icon[0]} id={props.icon[1]} />
        </ActionButton>
    );
};

export const IconNavLink: Component<{ selected?: boolean; icon: [string, string] } & NavLinkProps> = (_props) => {
    const propsWithDefaults = mergeProps({ class: '' }, _props);
    const [props, propsRest] = splitProps(propsWithDefaults, ['icon', 'class', 'children']);

    return (
        <ActionNavLink
            quiet
            round
            activeClass="is-selected"
            inactiveClass=""
            class={`o-icon-nav__item ${props.class}`}
            {...propsRest}
        >
            <Icon source={props.icon[0]} id={props.icon[1]} />
        </ActionNavLink>
    );
};

export const IconNav: Component<{ vertical?: boolean } & JSX.HTMLAttributes<HTMLElement>> = (_props) => {
    const propsWithDefaults = mergeProps({ class: '' }, _props);
    const [props, propsRest] = splitProps(propsWithDefaults, ['vertical', 'class']);
    return <nav class={`o-icon-nav ${props.vertical ? 'o-icon-nav--vertical' : ''} ${props.class}`} {...propsRest} />;
};
