import { Exome } from 'exome';
import * as D from 'fp-ts/Date';
import * as F from 'fp-ts/function';
import * as M from 'fp-ts/Map';
import * as O from 'fp-ts/Ord';
import { match } from 'ts-pattern';

import { Notification as NotificationE, NotificationType } from '@/entities/notification';
import { ChannelNotificationMessage } from '@/entities/stream';

import { NoteNotification } from './notification/note';
import { NoteUserNotification } from './notification/noteUser';
import { PollVoteNotification } from './notification/pollVote';
import { ReactionNotification } from './notification/reaction';
import { UserNotification } from './notification/user';
import { Session } from './session';

export const SessionDateOrd = O.contramap(
    (
        notification:
            | NoteUserNotification
            | NoteNotification
            | UserNotification
            | ReactionNotification
            | PollVoteNotification,
    ) => notification.createdAt,
)(O.reverse(D.Ord));

export class NotificationList extends Exome {
    notificationsById = new Map<
        string,
        NoteUserNotification | NoteNotification | UserNotification | ReactionNotification | PollVoteNotification
    >();

    constructor(readonly session: Session) {
        super();

        session.streamApi.subscribeNotifications(this.streamUpdate);
    }

    private streamUpdate = (msg: ChannelNotificationMessage) => {
        this.upsert(msg.body);
    };

    private manualEmit() {
        /* noop */
    }

    get list() {
        return F.pipe(this.notificationsById, M.values(SessionDateOrd));
    }

    add(data: NotificationE) {
        const note = match(data)
            .with({ type: NotificationType.Reply }, (data) => new NoteUserNotification(this.session, data))
            .with({ type: NotificationType.Mention }, (data) => new NoteUserNotification(this.session, data))
            .with({ type: NotificationType.Quote }, (data) => new NoteUserNotification(this.session, data))
            .with({ type: NotificationType.Renote }, (data) => new NoteUserNotification(this.session, data))
            .with({ type: NotificationType.Follow }, (data) => new UserNotification(this.session, data))
            .with({ type: NotificationType.FollowRequestAccepted }, (data) => new UserNotification(this.session, data))
            .with({ type: NotificationType.FollowRequestReceived }, (data) => new UserNotification(this.session, data))
            .with({ type: NotificationType.Reaction }, (data) => new ReactionNotification(this.session, data))
            .with({ type: NotificationType.PollVote }, (data) => new PollVoteNotification(this.session, data))
            .with({ type: NotificationType.PollEnded }, (data) => new NoteNotification(this.session, data))
            .run();

        this.notificationsById.set(data.id, note);
        return note;
    }

    upsert = (data: NotificationE) => {
        let notification = this.notificationsById.get(data.id);

        if (notification) {
            if (notification.type === data.type) {
                notification.update(data as any);
            }
        } else {
            notification = this.add(data);
        }

        return notification;
    };

    get = (id: string) => this.notificationsById.get(id);

    delete = (id: string) => {
        const notification = this.get(id);

        if (notification) {
            this.notificationsById.delete(id);
            this.manualEmit();
        }

        return notification;
    };

    fetch = async (params: {
        limit?: number;
        sinceId?: string;
        untilId?: string;
        following?: boolean;
        unreadOnly?: boolean;
        markAsRead?: boolean;
        includeTypes?: string[];
        excludeTypes?: string[];
    }) => {
        const notifications = await this.session.notificationApi.fetch({ limit: 11, ...params });
        for (const notification of notifications) {
            this.upsert(notification);
        }
    };

    fetchNewer = async (size: number) => {
        const sinceId = this.list[0]?.id;
        await this.fetch({ limit: size, sinceId });
    };

    fetchOlder = async (size: number) => {
        const untilId = this.list[this.list.length - 1]?.id;
        await this.fetch({ limit: size, untilId });
    };
}
