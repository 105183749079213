import './store/debug';

import { IntlProvider } from '@cookbook/solid-intl';
import { hashIntegration, pathIntegration, Router } from '@solidjs/router';
import ky from 'ky';
import { Component, createResource, onError } from 'solid-js';
import { render } from 'solid-js/web';

import { useStore } from './lib/exome/solid';
import { StaticConfig, staticConfig } from './staticConfig';
import { globalConfig as globalConfig_ } from './store/globalConfig';
import { RootView } from './ui/views/Root';

const Root: Component<{ config: StaticConfig }> = (props) => {
    const globalConfig = useStore(() => globalConfig_);

    const [messages] = createResource(
        () => globalConfig().locale,
        (locale) => ky.get(`./lang/${locale}.json`).json<Record<string, string>>(),
    );

    onError((e) => console.error(e));

    return (
        <IntlProvider
            locale={globalConfig().locale}
            messages={messages() ?? {}}
            defaultRichTextElements={{ b: (c) => <strong>{c}</strong> }}
        >
            <Router source={props.config.hashRouting ? hashIntegration() : pathIntegration()}>
                <RootView />
            </Router>
        </IntlProvider>
    );
};

(async () => {
    const config = await staticConfig;

    render(() => <Root config={config} />, document.body);
})();

//

if (process.env.NODE_ENV !== 'production') {
    new EventSource('/esbuild').addEventListener('change', () => location.reload());
}

const enableFocusIndicator = (e: KeyboardEvent) => {
    if (e.key !== 'Tab') {
        return;
    }

    document.body.classList.add('t-keyboard');
    document.removeEventListener('keydown', enableFocusIndicator);
    document.addEventListener('mousedown', disableFocusIndicator);
};

const disableFocusIndicator = () => {
    document.body.classList.remove('t-keyboard');

    document.removeEventListener('mousedown', disableFocusIndicator);
    document.addEventListener('keydown', enableFocusIndicator);
};

document.addEventListener('keydown', enableFocusIndicator);
