import { Component, Show } from 'solid-js';

import { useStore } from '@/lib/exome/solid';
import { Note as NoteStore } from '@/store/note';

import { Attachments } from '../../objects/Attachments';
import { ExtendedMarkdown } from '../../objects/ExtendedMarkdown';
import { NoteHeader } from './NoteHeader';

export const NoteRenote: Component<{ note: NoteStore; referenceNoteId?: string }> = (props) => {
    const note = useStore(() => props.note);

    return (
        <Show when={!props.referenceNoteId || note().id !== props.referenceNoteId}>
            <section class="c-note__embed o-bubble">
                <NoteHeader note={props.note} noMenu />
                <NoteContent note={props.note} noRenote />
            </section>
        </Show>
    );
};

export const NoteContent: Component<{
    note: NoteStore;
    large?: boolean;
    referenceNoteId?: string;
    noRenote?: boolean;
}> = (props) => {
    const note = useStore(() => props.note);

    return (
        <>
            <Show when={note().text} keyed>
                {(text) => (
                    <section
                        class={`c-note__body ${
                            props.large ? 'c-note__body--large' : ''
                        } s-links s-links--colored s-blockquotes s-blockquotes--compact s-code s-lists s-lists--compact`}
                    >
                        <ExtendedMarkdown
                            text={text}
                            customEmojis={note().emojis}
                            originHost={note().host}
                            session={note().session}
                            emojiSize="150"
                            noInlineImages
                        />
                    </section>
                )}
            </Show>

            <Show when={note().files.length} keyed>
                {(len) => (
                    <section
                        class={`c-note__attachments c-note__attachments--grid c-note__attachments--grid-${len} ${
                            len > 4 ? 'c-note__attachments--compact' : ''
                        }`}
                    >
                        <Attachments attachments={note().files} />
                    </section>
                )}
            </Show>

            <Show when={!props.noRenote && note().renote} keyed>
                {(renote) => <NoteRenote note={renote} referenceNoteId={props.referenceNoteId} />}
            </Show>
        </>
    );
};
