import * as io from 'io-ts';

import { EmojiC } from './emoji';

export const UserC = io.type({
    id: io.string,
    name: io.union([io.string, io.null]),
    username: io.string,
    host: io.union([io.string, io.null]),
    avatarUrl: io.union([io.string, io.null]),
    emojis: io.array(EmojiC),
});

export type User = io.TypeOf<typeof UserC>;
