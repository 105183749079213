import { defineMessages } from '@cookbook/solid-intl';

import { NoteVisibility } from '@/entities/note';

export const noteVisibilityIcon: Record<NoteVisibility, string> = {
    [NoteVisibility.Public]: 'globe',
    [NoteVisibility.Home]: 'home',
    [NoteVisibility.Followers]: 'users',
    [NoteVisibility.Specified]: 'envelope',
};

export const noteVisibilityMsg = {
    [NoteVisibility.Public]: defineMessages({
        label: {
            id: 'note.visibility.public.label',
            defaultMessage: 'Public',
        },
        description: {
            id: 'note.visibility.public.description',
            defaultMessage: 'Public',
        },
    }),
    [NoteVisibility.Home]: defineMessages({
        label: {
            id: 'note.visibility.home.label',
            defaultMessage: 'Unlisted',
        },
        description: {
            id: 'note.visibility.home.description',
            defaultMessage: 'Unlisted',
        },
    }),
    [NoteVisibility.Followers]: defineMessages({
        label: {
            id: 'note.visibility.followers.label',
            defaultMessage: 'Followers',
        },
        description: {
            id: 'note.visibility.followers.description',
            defaultMessage: 'Followers',
        },
    }),
    [NoteVisibility.Specified]: defineMessages({
        label: {
            id: 'note.visibility.specified.label',
            defaultMessage: 'Direct',
        },
        description: {
            id: 'note.visibility.specified.description',
            defaultMessage: 'Direct',
        },
    }),
} satisfies Record<NoteVisibility, ReturnType<typeof defineMessages>>;
