import { Component, ComponentProps, JSX, mergeProps, splitProps } from 'solid-js';
import { Dynamic } from 'solid-js/web';

import { FieldLabel } from '../objects/FieldLabel';

export const FormItem: Component<{ alignStart?: boolean } & ComponentProps<typeof FieldLabel>> = (_props) => {
    const propsWithDefaults = mergeProps({ class: '' }, _props);
    const [props, propsRest] = splitProps(propsWithDefaults, ['alignStart', 'class']);

    return (
        <FieldLabel
            class={`l-form__item ${props.class}`}
            labelClass="l-form__item-label"
            contentClass={`l-form__item-content ${props.alignStart ? 'l-form__item-content--align-start' : ''}`}
            {...propsRest}
        />
    );
};

export const Form: Component<
    { labelPosition?: 'left' | 'right' | 'above' } & JSX.FormHTMLAttributes<HTMLFormElement>
> = (_props) => {
    const propsWithDefaults = mergeProps({ labelPosition: 'above', class: '' }, _props);
    const [props, propsRest] = splitProps(propsWithDefaults, ['labelPosition', 'class', 'onSubmit']);

    return (
        <Dynamic
            component={props.onSubmit ? 'form' : 'div'}
            class={`l-form l-form--labels-${props.labelPosition} ${props.class}`}
            {...propsRest}
        />
    );
};
