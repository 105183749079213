import { Component, Index, mapArray, useContext } from 'solid-js';

import { DriveFile } from '@/entities/driveFile';
import { Lightbox } from '@/iro/objects/Lightbox';
import { OnDemandContext } from '@/iro/objects/OnDemand';
import icons from '#/icons.svg';

import { Attachment } from './Attachment';

export const Attachments: Component<{ attachments: DriveFile[] }> = (props) => {
    const onDemand = useContext(OnDemandContext);

    const attachments = mapArray(
        () => props.attachments,
        (attachment): Attachment => ({
            src: attachment.url!,
            thumbnail: attachment.thumbnailUrl ?? undefined,
            caption: attachment.comment,
            type: attachment.type,
            isSensitive: attachment.isSensitive,
        }),
    );

    const handleImageClick = (index: number, e: MouseEvent) => {
        e.preventDefault();
        onDemand((close) => <Lightbox iconSource={icons} items={attachments()} index={index} onClose={close} />);
    };

    return (
        <Index each={attachments()}>
            {(attachment, i) => <Attachment attachment={attachment()} onClick={[handleImageClick, i]} />}
        </Index>
    );
};
