import * as E from 'fp-ts/Either';
import { JsonFromString } from 'io-ts-types/JsonFromString';

import {
    ChannelConnect,
    ChannelDisconnect,
    ChannelMessageC,
    ChannelNoteMessage,
    ChannelNotificationMessage,
    ChannelSend,
    Message,
    NoteCapture,
    NoteUncapture,
    NoteUpdate,
    NoteUpdateC,
} from '@/entities/stream';
import { Mutex } from '@/lib/mutex';

interface Subscription {
    type: 'channel' | 'note';
    cb(msg: Message): void;
}

export class StreamApi {
    readonly socket;
    readonly mutex = new Mutex();
    readonly subscriptions = new Map<string, Subscription>();
    subscriptionId = 0;
    stopped = false;
    readonly buffer: string[] = [];

    constructor(socketUrl: string | URL) {
        this.socket = new WebSocket(socketUrl);
        this.socket.addEventListener('message', this.socketMessage.bind(this));
        this.socket.addEventListener('open', this.loop.bind(this));
        this.socket.addEventListener('error', () => (this.stopped = true));
        this.socket.addEventListener('close', () => (this.stopped = true));
    }

    private async loop() {
        while (!this.stopped) {
            await this.mutex.lock();

            const msgs = this.buffer.splice(0, this.buffer.length);

            for (const msg of msgs) {
                this.socket.send(msg);
            }
        }
    }

    private emit(msg: Message) {
        for (const [id, sub] of this.subscriptions) {
            if (msg.body.id === id) {
                sub.cb(msg);
                return;
            }
        }
    }

    private push(msg: string) {
        this.buffer.push(msg);
        this.mutex.release();
    }

    socketMessage(e: MessageEvent<unknown>) {
        const json = JsonFromString.decode(e.data as string);

        if (E.isLeft(json)) {
            console.error('Expected JSON message');
            return;
        }

        const chanMsg = ChannelMessageC.decode(json.right);

        if (E.isRight(chanMsg)) {
            this.emit(chanMsg.right);
            return;
        }

        const noteMsg = NoteUpdateC.decode(json.right);

        if (E.isRight(noteMsg)) {
            this.emit(noteMsg.right);
            return;
        }

        console.error('Unhandled message', json);
    }

    channelConnect(params: ChannelConnect) {
        this.push(JSON.stringify(params));
    }

    channelDisconnect(params: ChannelDisconnect) {
        this.push(JSON.stringify(params));
    }

    channelSend(params: ChannelSend) {
        this.push(JSON.stringify(params));
    }

    noteCapture(params: NoteCapture) {
        this.push(JSON.stringify(params));
    }

    noteUncapture(params: NoteUncapture) {
        this.push(JSON.stringify(params));
    }

    subscribe(params: Omit<ChannelConnect['body'], 'id'>, cb: Subscription) {
        const id = `${this.subscriptionId++}`;

        this.subscriptions.set(id, cb);
        this.channelConnect({
            type: 'connect',
            body: {
                ...params,
                id,
            },
        });

        return id;
    }

    unsubscribe(id: string) {
        const sub = this.subscriptions.get(id);

        if (!sub) {
            return;
        }

        this.subscriptions.delete(id);

        if (sub.type === 'channel') {
            this.channelDisconnect({
                type: 'disconnect',
                body: {
                    id,
                },
            });
        } else {
            this.noteUncapture({
                type: 'unsubNote',
                body: {
                    id,
                },
            });
        }
    }

    subscribeTimeline(cb: (msg: ChannelNoteMessage) => void) {
        return this.subscribe(
            {
                channel: 'homeTimeline',
            },
            {
                type: 'channel',
                cb: (msg) => msg.type === 'channel' && msg.body.type === 'note' && cb(msg.body),
            },
        );
    }

    subscribeNotifications(cb: (msg: ChannelNotificationMessage) => void) {
        return this.subscribe(
            {
                channel: 'main',
            },
            {
                type: 'channel',
                cb: (msg) => msg.type === 'channel' && msg.body.type === 'notification' && cb(msg.body),
            },
        );
    }

    subscribeNote(cb: (msg: NoteUpdate['body']) => void) {
        return this.subscribe(
            {
                channel: 'homeTimeline',
            },
            {
                type: 'note',
                cb: (msg) => msg.type === 'noteUpdated' && cb(msg.body),
            },
        );
    }
}
