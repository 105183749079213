import { useIntl } from '@cookbook/solid-intl';
import { Component, For, Show, useContext } from 'solid-js';

import { noteVisibilityIcon, noteVisibilityMsg } from '@/i18n/noteVisibility';
import { Icon } from '@/iro/objects/Icon';
import { OnDemandContext } from '@/iro/objects/OnDemand';
import { Time } from '@/iro/objects/Time';
import { useStore } from '@/lib/exome/solid';
import { Note as NoteStore } from '@/store/note';
import { ComposeModal } from '@/ui/modals/ComposeModal';
import { Emoji } from '@/ui/objects/Emoji';
import icons from '#/icons.svg';

import { NoteInteractionButton } from './NoteInteractionButton';

export const NoteFooter: Component<{ note: NoteStore; compact?: boolean }> = (props) => {
    const onDemand = useContext(OnDemandContext);
    const note = useStore(() => props.note);
    const intl = useIntl();

    const reactions = () => Object.entries(note().reactions).sort((r1, r2) => r2[1] - r1[1]);

    const composeNote = () => {
        onDemand((close) => <ComposeModal onClose={close} replyTo={props.note} />);
    };

    return (
        <>
            <Show when={!props.compact}>
                <footer class="c-note__footer c-note__footer--compact">
                    <small class="c-note__footer-section">
                        <Time date={note().createdAt} locale={intl.locale} />
                        <span class="u-ml-50 u-mr-50"> · </span>
                        <Icon source={icons} id={noteVisibilityIcon[note().visibility]} />{' '}
                        {intl.formatMessage(noteVisibilityMsg[note().visibility].label)}
                    </small>
                </footer>
            </Show>

            <footer
                class={`c-note__footer c-note__footer--wrap-reverse ${props.compact ? 'c-note__footer--compact' : ''}`}
            >
                <section class="c-note__footer-section c-note__footer-section--gaps">
                    <NoteInteractionButton
                        color="blue"
                        count={note().repliesCount}
                        onClick={composeNote}
                        title={intl.formatMessage({ id: 'component.note.action.reply.label', defaultMessage: 'Reply' })}
                    >
                        <Icon source={icons} id="message" />
                    </NoteInteractionButton>

                    <NoteInteractionButton
                        color="green"
                        count={note().renoteCount}
                        title={intl.formatMessage({
                            id: 'component.note.action.renote.label',
                            defaultMessage: 'Renote',
                        })}
                    >
                        <Icon source={icons} id="repeat" />
                    </NoteInteractionButton>

                    <NoteInteractionButton
                        color="red"
                        count={note().reactionsCount}
                        title={intl.formatMessage({
                            id: 'component.note.action.react.label',
                            defaultMessage: 'React',
                        })}
                    >
                        <Show when={note().myReaction} keyed fallback={<Icon source={icons} id="smile" />}>
                            {(myReaction) => <Emoji emoji={myReaction} customEmojis={note().emojis} icon />}
                        </Show>
                    </NoteInteractionButton>
                </section>

                <section class="c-note__footer-section c-note__footer-section--reactions c-reactions">
                    <For each={reactions()}>
                        {([emoji, count]) => (
                            <span
                                class="c-reactions__reaction"
                                title={intl.formatMessage(
                                    {
                                        id: 'component.note.reaction.tooltip',
                                        defaultMessage: `{count, plural,
                                            one   {# user reacted with {emoji}}
                                            other {# users reacted with {emoji}}
                                        }`,
                                    },
                                    { count, emoji },
                                )}
                            >
                                <Emoji emoji={emoji} customEmojis={note().emojis} noTitle />
                            </span>
                        )}
                    </For>
                </section>
            </footer>
        </>
    );
};
