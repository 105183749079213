import { Content } from 'mdast';
import { Root } from 'mdast-util-find-and-replace/lib';
import { fromMarkdown } from 'mdast-util-from-markdown';
import { Config } from 'mdast-util-from-markdown/lib';
import { Extension } from 'micromark-util-types';
import { Component, createMemo, For, JSX, Match, Show, splitProps, Switch } from 'solid-js';

import { isMatchingN } from '@/lib/ts-pattern/util';

import { H1, H2, H3, H4, H5, H6 } from './Heading';
import { Table, TableCell, TableRow } from './Table';

export const MarkdownElements: Component<{
    els: Content[];
    noLinks?: boolean;
    noInlineImages?: boolean;
    children?: (el: Content) => JSX.Element;
}> = (_props) => {
    const [props, propsRest] = splitProps(_props, ['els']);

    return (
        <For each={props.els}>
            {(el) => (
                <Switch fallback={propsRest.children?.(el)}>
                    <Match when={isMatchingN({ type: 'paragraph' } as const, el)} keyed>
                        {(el) => (
                            <p>
                                <MarkdownElements els={el.children} {...propsRest} />
                            </p>
                        )}
                    </Match>

                    <Match when={isMatchingN({ type: 'heading', depth: 1 } as const, el)} keyed>
                        {(el) => (
                            <H1>
                                <MarkdownElements els={el.children} {...propsRest} />
                            </H1>
                        )}
                    </Match>

                    <Match when={isMatchingN({ type: 'heading', depth: 2 } as const, el)} keyed>
                        {(el) => (
                            <H2>
                                <MarkdownElements els={el.children} {...propsRest} />
                            </H2>
                        )}
                    </Match>

                    <Match when={isMatchingN({ type: 'heading', depth: 3 } as const, el)} keyed>
                        {(el) => (
                            <H3>
                                <MarkdownElements els={el.children} {...propsRest} />
                            </H3>
                        )}
                    </Match>

                    <Match when={isMatchingN({ type: 'heading', depth: 4 } as const, el)} keyed>
                        {(el) => (
                            <H4>
                                <MarkdownElements els={el.children} {...propsRest} />
                            </H4>
                        )}
                    </Match>

                    <Match when={isMatchingN({ type: 'heading', depth: 5 } as const, el)} keyed>
                        {(el) => (
                            <H5>
                                <MarkdownElements els={el.children} {...propsRest} />
                            </H5>
                        )}
                    </Match>

                    <Match when={isMatchingN({ type: 'heading', depth: 6 } as const, el)} keyed>
                        {(el) => (
                            <H6>
                                <MarkdownElements els={el.children} {...propsRest} />
                            </H6>
                        )}
                    </Match>

                    <Match when={isMatchingN({ type: 'blockquote' } as const, el)} keyed>
                        {(el) => (
                            <blockquote>
                                <MarkdownElements els={el.children} {...propsRest} />
                            </blockquote>
                        )}
                    </Match>

                    <Match when={isMatchingN({ type: 'list', ordered: true } as const, el)} keyed>
                        {(el) => (
                            <ol start={el.start ?? undefined}>
                                <MarkdownElements els={el.children} {...propsRest} />
                            </ol>
                        )}
                    </Match>

                    <Match when={isMatchingN({ type: 'list' } as const, el)} keyed>
                        {(el) => (
                            <ul>
                                <MarkdownElements els={el.children} {...propsRest} />
                            </ul>
                        )}
                    </Match>

                    <Match when={isMatchingN({ type: 'listItem' } as const, el)} keyed>
                        {(el) => (
                            <li>
                                <MarkdownElements els={el.children} {...propsRest} />
                            </li>
                        )}
                    </Match>

                    <Match when={isMatchingN({ type: 'table' } as const, el)} keyed>
                        {(el) => (
                            <Table>
                                <MarkdownElements els={el.children} {...propsRest} />
                            </Table>
                        )}
                    </Match>

                    <Match when={isMatchingN({ type: 'tableRow' } as const, el)} keyed>
                        {(el) => (
                            <TableRow>
                                <MarkdownElements els={el.children} {...propsRest} />
                            </TableRow>
                        )}
                    </Match>

                    <Match when={isMatchingN({ type: 'tableCell' } as const, el)} keyed>
                        {(el) => (
                            <TableCell>
                                <MarkdownElements els={el.children} {...propsRest} />
                            </TableCell>
                        )}
                    </Match>

                    <Match when={isMatchingN({ type: 'code' } as const, el)} keyed>
                        {(el) => (
                            <pre>
                                <code>{el.value}</code>
                            </pre>
                        )}
                    </Match>

                    <Match when={isMatchingN({ type: 'text' } as const, el)} keyed>
                        {(el) => el.value}
                    </Match>

                    <Match when={isMatchingN({ type: 'emphasis' } as const, el)} keyed>
                        {(el) => (
                            <em>
                                <MarkdownElements els={el.children} {...propsRest} />
                            </em>
                        )}
                    </Match>

                    <Match when={isMatchingN({ type: 'strong' } as const, el)} keyed>
                        {(el) => (
                            <strong>
                                <MarkdownElements els={el.children} {...propsRest} />
                            </strong>
                        )}
                    </Match>

                    <Match when={isMatchingN({ type: 'delete' } as const, el)} keyed>
                        {(el) => (
                            <del>
                                <MarkdownElements els={el.children} {...propsRest} />
                            </del>
                        )}
                    </Match>

                    <Match when={isMatchingN({ type: 'inlineCode' } as const, el)} keyed>
                        {(el) => <code>{el.value}</code>}
                    </Match>

                    <Match when={isMatchingN({ type: 'break' } as const, el)}>
                        <br />
                    </Match>

                    <Match when={isMatchingN({ type: 'html' } as const, el)} keyed>
                        {(el) => el.value}
                    </Match>

                    <Match when={isMatchingN({ type: 'link' } as const, el)} keyed>
                        {(el) => (
                            <Show
                                when={!propsRest.noLinks}
                                fallback={<MarkdownElements els={el.children} {...propsRest} />}
                            >
                                <a href={el.url} title={el.title ?? undefined} target="_blank">
                                    <MarkdownElements els={el.children} {...propsRest} />
                                </a>
                            </Show>
                        )}
                    </Match>

                    <Match when={isMatchingN({ type: 'image' } as const, el)} keyed>
                        {(el) => (
                            <Show when={!propsRest.noInlineImages} fallback={el.alt}>
                                <img src={el.url} title={el.title ?? undefined} alt={el.alt ?? undefined} />
                            </Show>
                        )}
                    </Match>
                </Switch>
            )}
        </For>
    );
};

export const Markdown: Component<{
    text: string | Root;
    noLinks?: boolean;
    extensions?: Extension[];
    mdastExtensions?: (Partial<Config> | Partial<Config>[])[];
    children?: (el: Content) => JSX.Element;
}> = (props) => {
    const ast = createMemo(() =>
        typeof props.text === 'string'
            ? fromMarkdown(props.text, 'utf8', {
                  extensions: props.extensions,
                  mdastExtensions: props.mdastExtensions,
              })
            : props.text,
    );
    return <MarkdownElements els={ast().children} children={props.children} />;
};
