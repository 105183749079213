import { Accessor, createEffect, createSignal, onCleanup } from 'solid-js';

const cache: Record<string, { needsCollapse: boolean; maxHeight: number; tolerance: number }> = {};

export const useCollapse = (id: Accessor<string>, maxHeight: Accessor<number | undefined>, tolerance = 0) => {
    let el: HTMLElement | undefined;

    const [needsCollapse, setNeedsCollapse] = createSignal(true);
    const [collapsed, setCollapsed] = createSignal(true);

    const isCollapsed = () => needsCollapse() && collapsed();

    createEffect(() => {
        if (!el) {
            return;
        }

        const mh = maxHeight();

        if (isCollapsed()) {
            if (mh === undefined) {
                el.style.maxHeight = '';
                el.style.display = 'none';
            } else {
                el.style.maxHeight = `${mh}px`;
            }
        } else {
            el.style.maxHeight = '';
            el.style.display = '';
        }
    });

    const update = () => {
        const mh = maxHeight();

        if (!el || mh === undefined) {
            return;
        }

        const scrollHeight = el.scrollHeight;
        const needsCollapse = scrollHeight > mh + tolerance;

        setNeedsCollapse(needsCollapse);
        cache[id()] = { maxHeight: mh, tolerance, needsCollapse };
    };

    const ro = new ResizeObserver(update);

    const mount = (el_: HTMLElement) => {
        el = el_;
        ro.observe(el);
    };

    createEffect(() => {
        const cached = cache[id()];

        if (cached && cached.maxHeight === maxHeight() && cached.tolerance === tolerance) {
            setNeedsCollapse(cached.needsCollapse);
        } else {
            update();
        }
    });

    onCleanup(() => ro.disconnect());

    return [mount, needsCollapse, isCollapsed, setCollapsed] as const;
};
