import { findAndReplace, RegExpMatchObject } from 'mdast-util-find-and-replace';
import { CompileContext, Extension, Token } from 'mdast-util-from-markdown';
import { PhrasingContent, Root } from 'mdast-util-from-markdown/lib';

import { previous } from './util';

const urlRegex = /(?:https?|gopher|gemini):\/\/(?:[a-zA-Z0-9_-]+\.)+[a-z]+(?:\/[a-zA-Z0-9/#@$!?%:;&'()=+~*[\]_,.-]*)?/g;

export function autolinkFromMarkdown(): Extension {
    return {
        transforms: [transformAutolinks],
        enter: {
            enterAutolink,
        },
        exit: {
            exitAutolink,
        },
    };

    function enterAutolink(this: CompileContext, token: Token) {
        this.enter({ type: 'link', url: '', children: [] }, token);
    }

    function exitAutolink(this: CompileContext, token: Token) {
        this.exit(token);
    }

    function transformAutolinks(tree: Root) {
        findAndReplace(tree, [[urlRegex, findAutolink]]);
    }

    function findAutolink(url: string, match: RegExpMatchObject): PhrasingContent | false {
        if (!previous(match)) {
            return false;
        }

        return { type: 'link', url, children: [{ type: 'text', value: url }] };
    }
}
