import { Exome } from 'exome';
import * as io from 'io-ts';

import { UserC } from '@/entities/user';
import { mentionRegex } from '@/services/mentions';

import { Session } from './session';
import { User } from './user';

export class UserList extends Exome {
    private usersById = new Map<string, User>();
    private usersByName = new Map<string, User>();

    constructor(readonly session: Session) {
        super();
    }

    private manualEmit() {
        /* noop */
    }

    select = (id: string) => (mentionRegex.test(id) ? this.usersByName : this.usersById);

    create(data: Pick<io.TypeOf<typeof UserC>, 'id' | 'username'> & Partial<io.TypeOf<typeof UserC>>) {
        const user = new User(this.session, data);
        this.usersById.set(user.id, user);
        this.usersByName.set(user.fullUsername, user);
        return user;
    }

    upsert = (data: Pick<io.TypeOf<typeof UserC>, 'id' | 'username'> & Partial<io.TypeOf<typeof UserC>>) => {
        let user = this.get(data.id);

        if (user) {
            user.update(data);
        } else {
            user = this.create(data);
        }

        return user;
    };

    get = (id: string) => this.select(id).get(id);

    delete = (id: string) => {
        const user = this.get(id);

        if (user) {
            this.usersById.delete(user.id);
            this.usersByName.delete(user.fullUsername);
            user.markDeleted();
            this.manualEmit();
        }

        return user;
    };
}
